import { FC, useMemo, useRef, useState } from 'react';
import { RouteType } from '../utils/types';
import { Link, useLocation } from 'react-router-dom';
import useOutsideAlerter from '../hooks/useOnClickOutside';
import MenuDropdown from './MenuDropdown';
import Dropdown from './Dropdown';

interface Props {
  route: RouteType;
}

const NavLink: FC<Props> = ({ route }) => {
  const { pathname } = useLocation();

  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);
  useOutsideAlerter(dropdownRef, () => setShowDropdown(false));

  const active = useMemo(
    () =>
      route.href === pathname ||
      (route.hrefs && route.hrefs.find((i) => i.href === pathname)),
    [pathname, route],
  );

  if (route.mutliple) {
    return (
      <div
        ref={dropdownRef}
        className={`${
          active ? 'text-secondary custom-font-bold' : 'text-white'
        } flex items-center gap-1 cursor-pointer  text-lg hover:border-b hover:border-white dark:hover:border-white transition-all duration-300`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {active && (
          <img
            src="/assets/icons/active-link.svg"
            className="w-[43px] h-[56px] absolute -left-3 -top-5"
            alt="active"
          />
        )}
        {route.name}
        <img
          src={
            active
              ? '/assets/icons/chevron-down-green.svg'
              : '/assets/icons/chevron-down.svg'
          }
          className="h-4 w-4"
          alt="down"
        />
        <Dropdown isOpen={showDropdown}>
          <MenuDropdown links={route.hrefs} />
        </Dropdown>
      </div>
    );
  }

  return (
    <Link
      to={route.href || '/'}
      className={`${
        active ? 'text-secondary custom-font-bold' : 'text-white'
      } text-white text-lg relative hover:border-b hover:border-white dark:hover:border-white transition-all duration-300`}
    >
      {active && (
        <img
          src="assets/icons/active-link.svg"
          className="w-[43px] h-[56px] absolute -left-3 -top-5"
          alt="active"
        />
      )}
      {route.name}
    </Link>
  );
};

export default NavLink;
