import { FC, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useTheme } from '../context/ThemeContext';
import { useTranslation } from 'react-i18next';

interface Props {
  faq: {
    question: string;
    answer: string;
  };
}

const FaqItem: FC<Props> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();

  const { t } = useTranslation();

  const animationProps = useSpring({
    maxHeight: isOpen ? '500px' : '0px',
    opacity: isOpen ? 1 : 0,
    overflow: 'hidden',
    config: { tension: 300, friction: 30 },
  });

  return (
    <div className="border-b-2 border-black dark:border-white hover:scale-[1.01] transition-[300]">
      <div
        className="flex items-center justify-between px-2 py-2 min-h-[84px] cursor-pointer select-none"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <p className="text-black dark:text-white text-lg custom-font-bold">
          {t(faq.question)}
        </p>
        <img
          src={
            theme === 'dark'
              ? '/assets/images/landing/arrow.svg'
              : '/assets/images/landing/arrow-black.svg'
          }
          alt="arrow"
          className={`h-8 w-8 ${
            isOpen ? 'rotate-180' : 'rotate-0'
          } transition duration-300`}
        />
      </div>
      <animated.div style={animationProps}>
        <div className="text-black dark:text-white border-t border-black dark:border-white py-5">
          <p className="text-lg list-disc pl-2 whitespace-break-spaces">
            {t(faq.answer)}
          </p>
        </div>
      </animated.div>
    </div>
  );
};

export default FaqItem;
