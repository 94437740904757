import { Dispatch, FC, SetStateAction } from 'react';

interface Props {
  accounts: {
    id: number;
    name: string;
    icon: string;
  }[];
  selectedTab: {
    id: number;
    name: string;
    icon: string;
  };
  setSelectedTab: Dispatch<
    SetStateAction<{
      id: number;
      name: string;
      icon: string;
    }>
  >;
  onClose: () => void;
}

const TradingDropdown: FC<Props> = ({
  accounts,
  selectedTab,
  setSelectedTab,
  onClose,
}) => {
  return (
    <div className="bg-primary border-2 border-white border-t-0 px-2 z-10">
      {accounts.map((account, index) => (
        <div
          onClick={() => {
            setSelectedTab(account);
            onClose();
          }}
          key={index}
          className={`${
            selectedTab.name === account.name ? 'custom-font-bold' : ''
          } text-center text-white h-[50px] flex items-center justify-center cursor-pointer border-b border-white last:border-b-0`}
        >
          {account.name}
        </div>
      ))}
    </div>
  );
};

export default TradingDropdown;
