import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../utils/enums';
import Button from './Button';
import NavLink from './NavLink';

import { useTheme } from '../context/ThemeContext';
import { useTransition, animated } from '@react-spring/web';
import SelectLanguage from './SelectLanguage';
import { useEffect, useState } from 'react';
import MobileNavbar from './MobileNavbar';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { toggleTheme, theme } = useTheme();

  const { pathname } = useLocation();

  const [openMobileNavbar, setOpenMobileNavbar] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setOpenMobileNavbar(false);
  }, [pathname]);

  const routes = [
    {
      name: t('home'),
      href: ROUTES.home,
      mutliple: false,
    },
    {
      name: t('trading'),
      mutliple: true,
      hrefs: [
        {
          name: t('tradingAccount'),
          href: ROUTES.tradingAccount,
          links: [
            {
              name: t('common'),
              href: ROUTES.common,
            },
            // {
            //   name: t('protectionAccounts'),
            //   href: ROUTES.protectionAccounts,
            // },
            {
              name: t('demo'),
              href: ROUTES.demo,
            },
          ],
        },
        {
          name: t('markets'),
          href: ROUTES.financialMarkets,
          links: [
            {
              name: t('commodities'),
              href: ROUTES.commodities,
            },
            {
              name: t('stocks'),
              href: ROUTES.stocks,
            },
            {
              name: t('indices'),
              href: ROUTES.indices,
            },
            {
              name: t('forex'),
              href: ROUTES.forex,
            },
            {
              name: t('metals'),
              href: ROUTES.metals,
            },
          ],
        },
      ],
    },
    {
      name: t('middleman'),
      hrefs: [
        {
          name: t('referAFriend'),
          href: ROUTES.referFriend,
          links: [],
        },
        {
          name: t('IpProxy'),
          href: ROUTES.ibProxy,
          links: [],
        },
      ],
      mutliple: true,
    },
    {
      name: t('education'),
      hrefs: [
        {
          name: t('articles'),
          href: ROUTES.articles,
          links: [],
        },
        {
          name: t('howDoYouDoIt'),
          href: ROUTES.howDoYouDoIt,
          links: [],
        },
        {
          name: t('commonQuestions'),
          href: ROUTES.commonQuestion,
          links: [],
        },
      ],
      mutliple: true,
    },
    {
      name: t('offers'),
      href: ROUTES.offers,
      mutliple: false,
    },
    {
      name: t('aboutUs'),
      href: ROUTES.about,
      mutliple: false,
    },
  ];

  const transitions = useTransition(theme, {
    from: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
  });

  const handleToggle = () => {
    toggleTheme(theme === 'dark' ? 'light' : 'dark');
  };

  return (
    <>
      <div className="bg-primary h-[72px] xl:h-[106px] fixed left-0 top-0 w-full z-20 border-b border-white px-1 xl:px-0">
        <div className="flex items-center h-full">
          <div className="container h-full mx-auto flex items-center justify-between">
            <div className="flex items-center">
              <img
                onClick={() => setOpenMobileNavbar(true)}
                src="/assets/icons/hamburger.svg"
                alt="hamburger"
                className="mr-2 block xl:hidden cursor-pointer"
              />
              <Link to={ROUTES.home}>
                <img
                  className="h-5 xl:h-10 2xl:h-[53px] w-[100px] xl:w-auto flex-shrink-0 object-cover object-left hover:scale-105 transition-[1000]"
                  src="/assets/icons/logo.svg"
                  alt="logo"
                />
              </Link>
            </div>
            <div className="hidden xl:flex items-center gap-6 flex-shrink-0 rtl:ml-4 rtl:mr-4 mr-4">
              {routes.map((route, index) => (
                <NavLink key={index} route={route} />
              ))}
            </div>
            <div className="flex items-center xl:gap-4 flex-shrink-0">
              <div
                className="relative h-6 w-6 hidden xl:block"
                onClick={handleToggle}
              >
                {transitions((style, item) =>
                  item === 'dark' ? (
                    <animated.img
                      src="/assets/icons/moon.svg"
                      alt="moon"
                      // @ts-ignore
                      style={style}
                      className="cursor-pointer hidden xl:block hover:scale-125 transition-[1000]"
                    />
                  ) : (
                    <animated.img
                      src="/assets/icons/sun.svg"
                      alt="sun"
                      // @ts-ignore
                      style={style}
                      className="cursor-pointer hidden xl:block hover:scale-125 transition-[1000]"
                    />
                  ),
                )}
              </div>
              <div className="hidden xl:flex items-center justify-center mb-1">
                <SelectLanguage />
              </div>
              <span
                onClick={() =>
                  window.open('https://my.damu.co/login', '_blank')
                }
                className="custom-font-medium text-sm text-white cursor-pointer hidden xl:block hover:border-b border-white transition-all duration-300"
              >
                {t('signIn')}
              </span>
              <div className="flex items-center xl:gap-1">
                <Button
                  onClick={() =>
                    window.open('https://my.damu.co/register', '_blank')
                  }
                  white
                  className="h-10 !px-1 !md:px-4 2xl:h-14 text-xs md:text-base rtl:ml-1 mr-2 md:mr-2"
                >
                  {t('realAccount')}
                </Button>
                <Button
                  onClick={() =>
                    window.open('https://my.damu.co/register', '_blank')
                  }
                  white
                  className="h-10 !px-1 !md:px-4 2xl:h-14 text-xs md:text-base"
                >
                  {t('demoAccount')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex xl:hidden">
        <MobileNavbar
          isOpen={openMobileNavbar}
          setIsOpen={setOpenMobileNavbar}
          routes={routes}
        />
      </div>
    </>
  );
};

export default Navbar;
