import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Moonstar from '../../components/Moonstar';
import { useEffect } from 'react';
import HeroLowerImg from '../../assets/images/hero-lower-center.png';

const Hero = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const main = document.querySelector('#main');
    const state = {
      columns: 0,
      rows: 0,
      total: 1,
    };

    function getGridSize() {
      // @ts-ignore
      const columns = Math.floor(main?.clientWidth / 50);
      // @ts-ignore
      const rows = Math.floor(main?.clientHeight / 50);
      state.columns = columns;
      state.rows = rows;
      state.total = columns * rows;
    }

    function draw_boxes() {
      getGridSize();
      // @ts-ignore
      main.innerHTML = '';

      for (let i = 0; i < state.total; i++) {
        const fragment = document.createDocumentFragment();
        const div = document.createElement('div');
        div.classList.add('box');
        fragment.appendChild(div);

        // @ts-ignore
        main.appendChild(fragment);
      }
    }

    window.addEventListener('resize', draw_boxes);
    draw_boxes();

    return () => {
      window.removeEventListener('resize', draw_boxes);
    };
  }, []);

  return (
    <div className="xl:min-h-screen min-w-full flex flex-col items-center relative">
      <img
        src={'/assets/images/landing/hero.png'}
        alt="hero"
        className="inset-0  absolute object-cover z-[1] xl:flex"
      />
      <div className="h-[1px] w-full relative z-0">
        <div className="purple-line-1"></div>
        <div className="purple-line-2"></div>
        <div className="purple-line-3"></div>
        <div className="purple-line-4"></div>
        <div className="green-line-1"></div>
        <div className="green-line-2"></div>
        <div className="green-line-3"></div>
        <main id="main"></main>
      </div>
      <div className="w-full xl:w-1/2 pt-10 xl:pt-20 z-[1]">
        <h1 className="custom-font-bold text-light dark:text-dark text-center text-[32px] leading-[48px] xl:text-[64px] xl:leading-[96px] mb-5">
          {t('soarWithYourTrades')}
        </h1>
        <p className="text-lg text-light dark:text-dark text-center leading-[27px] mb-2">
          {t('haveYouHadThe')}
        </p>
        <div className="flex justify-center items-center w-full">
          <Button
            onClick={() => window.open('https://my.damu.co/register', '_blank')}
            className="!px-6 mr-4"
          >
            {t('openNewAccount')}
          </Button>
          <Button
            noAnim
            className="!bg-transparent !text-black dark:!text-white hover:scale-125 transition-[300]"
            onClick={() => window.open('https://my.damu.co', '_blank')}
          >
            {t('signIn')}
          </Button>
        </div>
      </div>
      <div className="pt-40 xl:pt-32 relative w-full block">
        <div className="flex flex-col items-center justify-center z-[1] w-full relative">
          <div className="mb-2">
            <Moonstar>{t('fastAndReliableExecution')}</Moonstar>
          </div>
          <div className="flex justify-center gap-1 xl:gap-5">
            <div className="flex flex-col gap-10 justify-between xl:justify-start  xl:gap-20">
              <Moonstar>{t('negativeBalanceProtection')}</Moonstar>
              <Moonstar>{t('noPriceGaps')}</Moonstar>
            </div>
            <div>
              <img
                src={HeroLowerImg}
                alt="hero-lower-center"
                className="h-auto w-full xl:h-[356px] xl:w-[650px]"
              />
            </div>
            <div className="flex flex-col gap-10 justify-between xl:justify-start xl:gap-20">
              <Moonstar>{t('lowSpread')}</Moonstar>
              <Moonstar>{t('islamicAccounts')}</Moonstar>
            </div>
          </div>
        </div>
        <img
          src={'/assets/images/landing/hero-lower.svg'}
          alt="hero-lower"
          className="h-[auto] w-full lg:h-[741px] lg:w-[741px] absolute top-10 lg:top-0 left-1/2 -translate-x-1/2 overflow-visible object-cover"
        />
      </div>
    </div>
  );
};

export default Hero;
