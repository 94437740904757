import { useTranslation } from 'react-i18next';
import FAQs from '../../../components/FAQs';
import Hero from '../../../containers/Trading/Hero';
import Markets from '../../../containers/Trading/Markets';
import { useEffect, useRef } from 'react';
import useQuery from '../../../hooks/useQuery';
import { financialFaqs } from '../../../utils/faqs';
import { ROUTES } from '../../../utils/enums';

const FinancialMarkets = () => {
  const { t } = useTranslation();

  const marketsRef = useRef<any>();

  const query = useQuery();

  const tab = query.get('tab');

  useEffect(() => {
    if (tab && marketsRef.current) {
      const topPosition = marketsRef.current.offsetTop - 105;

      window.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      });
    }
  }, [query, tab]);

  return (
    <div>
      <Hero />
      <div ref={marketsRef}>
        <Markets tab={tab} />
      </div>
      <FAQs
        label={t('marketsFaqs')}
        faqs={financialFaqs.slice(0, 3)}
        link={ROUTES.commonQuestionFinancial}
      />
    </div>
  );
};

export default FinancialMarkets;
