import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const HowDoYou = () => {
  const { t } = useTranslation();

  const [selectedVideo, setSelectedVideo] = useState<{
    lesson: string;
    year: string;
    name: string;
    url: string;
    title: string;
  } | null>(null);

  const arr = [
    {
      lesson: t('firstLesson'),
      year: '2024',
      name: t('internalTransfer'),
      url: 'https://www.youtube.com/embed/vCZ5u3mXIAk?autoplay=1',
      title:
        'شرح عمليات التحويل الداخلي في شركة الوساطة دامو | التحويل بين الحسابات والى المحفظة والعكس',
      img: '/assets/images/education/internal_transfer.JPEG',
    },
    {
      lesson: t('firstLesson'),
      year: '2024',
      name: t('withdrawal'),
      url: 'https://www.youtube.com/embed/64XLPn1y_uQ?autoplay=1',
      title:
        'شرح عمليات التحويل الداخلي في شركة الوساطة دامو | التحويل بين الحسابات والى المحفظة والعكس',
      img: '/assets/images/education/withdrawal.JPEG',
    },
    {
      lesson: t('firstLesson'),
      year: '2024',
      name: t('depositAndMyWallet'),
      url: 'https://www.youtube.com/embed/9yjYKWA82LQ?autoplay=1',
      title:
        'شرح عملية الايداع داخل شركة الوساطة دامو | طريقة انشاء محفظة داخلية',
      img: '/assets/images/education/deposit.JPEG',
    },
  ];

  return (
    <div className="grid lg:grid-cols-3 gap-5 mt-10 lg:px-5 relative">
      {arr.map((item, index) => {
        return (
          <div
            onClick={() => setSelectedVideo(item)}
            key={index}
            className="border-[3px] border-gray p-3"
            style={{
              background:
                'linear-gradient(to top, rgba(40, 36, 45, 0.4), rgba(109, 109, 109, 0.6))',
            }}
          >
            <div className="flex items-center justify-between bg-gray px-2 rounded-md h-11 mb-2">
              {/* <span className="custom-font-medium"> </span> */}
              <img
                src="/assets/images/education/damu.svg"
                alt="damu"
                height={40}
                width={40}
                className="z-10"
              />
              {/* <span className="custom-font-medium">{item.lesson}</span> */}
              <span className="custom-font-medium">{item.year}</span>
            </div>
            <div className="aspect-square bg-gray p-4 rounded-md flex flex-col justify-between relative">
              {/* <img
                src="/assets/images/education/damu.svg"
                alt="damu"
                height={80}
                width={80}
                className="z-10"
              /> */}
              <div />
              <img
                src={item.img}
                alt="damu"
                className="inset-0 absolute object-cover h-full"
              />
              <div className="z-10 bg-black/50 p-2">
                <p className="text-2xl text-center custom-font-bold text-white">
                  {item.name}
                </p>
              </div>
            </div>
          </div>
        );
      })}
      {selectedVideo && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black/70"
          onClick={() => setSelectedVideo(null)}
        >
          <iframe
            className="w-[80%] h-[80%]"
            src={selectedVideo.url}
            title={selectedVideo.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default HowDoYou;
