import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const LotsDone = () => {
  const { t } = useTranslation();

  const lotsDone = useMemo(() => 5, []);

  return (
    <div className="w-full lg:w-1/2 mt-5 px-2">
      <div className="flex items-center justify-center mt-5 h-[11px] md:h-[38px] overflow-hidden rounded-3xl bg-[rgba(176,176,176,1)] relative">
        <div
          className="absolute left-0 top-0 bottom-0 bg-primary rounded-3xl"
          style={{ right: `${100 - lotsDone * 10}%` }}
        ></div>
      </div>
      <div className="flex items-center justify-between mt-2">
        <span className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold">
          {t(lotsDone.toString())} {t('lotsDone')}
        </span>
        <span className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold">
          {t(lotsDone.toString())}/{t('15')}
        </span>
      </div>
    </div>
  );
};

export default LotsDone;
