import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-2 xl:mx-0">
      <div className="container mx-auto py-5">
        <h1 className="text-primary text-center text-[46px] lg:text-[58px] custom-font-bold border-b border-primary">
          {t('termsOfService')}
        </h1>
        <div className="py-5">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <a
              href="/assets/docs/Risk-disclosure.pdf"
              download
              className={`border-4 border-white relative p-5 py-10 lg:p-10 flex flex-col items-center bg-primary`}
            >
              <div className="w-[114px] h-[114px] rounded-full bg-white mb-4 flex items-center justify-center">
                <img
                  src="/assets/images/offers/damu-purple.svg"
                  alt="damu"
                  className="h-[67px] w-[67px]"
                />
              </div>
              <h1 className="text-center text-white text-4xl custom-font-bold">
                {t('riskDisclosure')}
              </h1>
            </a>
            <a
              href="/assets/docs/Trading-terms.pdf"
              download
              className={`border-4 border-white relative p-5 py-10 lg:p-10 flex flex-col items-center bg-primary`}
            >
              <div className="w-[114px] h-[114px] rounded-full bg-white mb-4 flex items-center justify-center">
                <img
                  src="/assets/images/offers/damu-purple.svg"
                  alt="damu"
                  className="h-[67px] w-[67px]"
                />
              </div>
              <h1 className="text-center text-white text-4xl custom-font-bold">
                {t('tradingTerms')}
              </h1>
            </a>
            <a
              href="/assets/docs/Business-partners-terms-and-conditions.pdf"
              download
              className={`border-4 border-white relative p-5 py-10 lg:p-10 flex flex-col items-center bg-primary`}
            >
              <div className="w-[114px] h-[114px] rounded-full bg-white mb-4 flex items-center justify-center">
                <img
                  src="/assets/images/offers/damu-purple.svg"
                  alt="damu"
                  className="h-[67px] w-[67px]"
                />
              </div>
              <h1 className="text-center text-white text-4xl custom-font-bold">
                {t('businessPartnersTermsAndConditions')}
              </h1>
            </a>
            <a
              href="/assets/docs/General-terms.pdf"
              download
              className={`border-4 border-white relative p-5 py-10 lg:p-10 flex flex-col items-center bg-primary`}
            >
              <div className="w-[114px] h-[114px] rounded-full bg-white mb-4 flex items-center justify-center">
                <img
                  src="/assets/images/offers/damu-purple.svg"
                  alt="damu"
                  className="h-[67px] w-[67px]"
                />
              </div>
              <h1 className="text-center text-white text-4xl custom-font-bold">
                {t('generalTermsOfUse')}
              </h1>
            </a>

            <a
              href="/assets/docs/Summary-of-antimoney-laundering.pdf"
              download
              className={`border-4 border-white relative p-5 py-10 lg:p-10 flex flex-col items-center bg-primary`}
            >
              <div className="w-[114px] h-[114px] rounded-full bg-white mb-4 flex items-center justify-center">
                <img
                  src="/assets/images/offers/damu-purple.svg"
                  alt="damu"
                  className="h-[67px] w-[67px]"
                />
              </div>
              <h1 className="text-center text-white text-4xl custom-font-bold">
                {t('summaryOfAntimoneyLaundering')}
              </h1>
            </a>
            <a
              href="/assets/docs/Funds-and-transfers.pdf"
              download
              className={`border-4 border-white relative p-5 py-10 lg:p-10 flex flex-col items-center bg-primary`}
            >
              <div className="w-[114px] h-[114px] rounded-full bg-white mb-4 flex items-center justify-center">
                <img
                  src="/assets/images/offers/damu-purple.svg"
                  alt="damu"
                  className="h-[67px] w-[67px]"
                />
              </div>
              <h1 className="text-center text-white text-4xl custom-font-bold">
                {t('fundsAndTransfer')}
              </h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
