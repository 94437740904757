import { FC } from 'react';
import FaqItem from './FaqItem';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { ROUTES } from '../utils/enums';

interface Props {
  faqs: {
    question: string;
    answer: string;
  }[];
  label: string;
  noButton?: boolean;
  link?: string;
}

const FAQs: FC<Props> = ({ faqs, label, noButton, link }) => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-2 xl:px-0 py-10 xl:py-20 flex flex-col items-center">
      {!noButton && (
        <Button
          onClick={() => navigate(link || ROUTES.commonQuestion)}
          className="!px-20 mr-4"
        >
          {label}
        </Button>
      )}
      <div className="w-full">
        {faqs.map((faq, index) => (
          <FaqItem key={index} faq={faq} />
        ))}
      </div>
    </div>
  );
};

export default FAQs;
