// import { useMemo, useState } from 'react';
// import Search from '../../../components/Search';

// import { COMMONQUESTIONSTAB } from '../../../utils/enums';
// import Tabs from '../../../containers/Education/Tabs';
import ArticlesList from '../../../containers/Education/ArticlesList';
import { useTranslation } from 'react-i18next';

const Articles = () => {
  const { t } = useTranslation();

  // const [activeTab, setActiveTab] = useState(COMMONQUESTIONSTAB.tab1);

  return (
    <>
      <div className="container mx-auto pt-5 lg:py-5 hidden lg:flex flex-col px-2 lg:px-0">
        <h1 className="text-[32px] w-full text-center py-5 lg:py-0 lg:text-[64px] text-black dark:text-white">
          {t('articles')}
        </h1>
        <div className="flex gap-10">
          <div className="flex-1">
            <ArticlesList />
          </div>
        </div>
      </div>
      <div className="container mx-auto pt-5 lg:py-5 flex lg:hidden flex-col px-2 lg:px-0">
        <h1 className="text-[32px] text-center lg:text-left py-5 lg:py-0 lg:text-[64px] text-black dark:text-white order-2 lg:order-1">
          {t('articles')}
        </h1>
        {/* <div className="order-1 lg:order-2">
          <Search />
        </div>
        <div className="order-3 overflow-x-auto">
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        </div> */}
        <div className="order-4">
          <ArticlesList />
        </div>
      </div>
    </>
  );
};

export default Articles;
