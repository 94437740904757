import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { useMemo, useState } from 'react';
import TradingDropdown from './TradingDropdown';
import { useSpring, animated } from '@react-spring/web';
import { Tooltip } from 'react-tooltip';
import {
  cryptoAccounts,
  fitAccounts,
  getTooltip,
  standardAccounts,
  stockAccounts,
} from '../../utils/constants';
import 'react-tooltip/dist/react-tooltip.css';

const Common = () => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState({
    id: 1,
    name: t('standard'),
    icon: '/assets/images/landing/standard.svg',
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const accounts = [
    {
      id: 1,
      name: 'Standard',
      icon: '/assets/images/landing/standard.svg',
    },
    {
      id: 2,
      name: 'Fit',
      icon: '/assets/images/landing/fit.svg',
    },
    {
      id: 3,
      name: 'Crypto',
      icon: '/assets/images/landing/bitcoin.svg',
    },
    {
      id: 4,
      name: 'Stock',
      icon: '/assets/images/landing/stocks.svg',
    },
  ];

  const data = useMemo(() => {
    switch (selectedTab.id) {
      case 1:
        return standardAccounts(t);
      case 2:
        return fitAccounts(t);
      case 3:
        return cryptoAccounts(t);
      case 4:
        return stockAccounts(t);

      default:
        return [];
    }
  }, [selectedTab.id, t]);

  const dropdownAnimation = useSpring({
    height: isDropdownOpen ? '500px' : '0px',
    opacity: isDropdownOpen ? 1 : 0,
    overflow: 'hidden',
  });

  return (
    <div className="my-20 w-full">
      <div className="relative">
        <div
          onClick={() => setIsDropdownOpen((prev) => !prev)}
          className="bg-primary mb-5 border-b-2 border-white h-[84px] rounded-t-lg cursor-pointer flex items-center justify-between px-5 hover:bg-primary/80 transition-[300]"
        >
          <div className="flex items-center">
            <img
              className="mr-5 rtl:ml-5"
              src={selectedTab.icon}
              alt={selectedTab.name}
              height={57}
              width={57}
            />
            <span className="text-white text-2xl custom-font-bold mt-2">
              {selectedTab.name}
            </span>
          </div>
          <img src="/assets/icons/arrow.svg" alt="arrow" />
        </div>
        <div className="absolute left-0 right-0 top-full z-10 ">
          <animated.div style={dropdownAnimation}>
            <TradingDropdown
              accounts={accounts}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onClose={() => setIsDropdownOpen(false)}
            />
          </animated.div>
        </div>
      </div>
      <div className="grid grid-cols-2 pb-2 items-end border-b border-black dark:border-white gap-2">
        <p className="!text-sm !lg:text-2xl text-black dark:text-white custom-font-bold">
          {t('properties')}
        </p>
      </div>
      {data.map((field, index) => {
        return (
          <div
            key={index}
            className="grid grid-cols-2 h-[65px] items-center border-b border-black dark:border-white gap-2"
          >
            <p className="!text-sm !lg:text-2xl text-black dark:text-white custom-font-bold flex justify-start gap-4 items-center">
              {field.field}
              <div
                className="relative"
                data-tooltip-id={field.field}
                data-tooltip-content={getTooltip(t, field.field)}
              >
                <Tooltip offset={40} id={field.field} />
                <img
                  src="/assets/icons/info.svg"
                  alt="info"
                  width={20}
                  height={20}
                  className="dark:hidden"
                />
                <img
                  src="/assets/icons/info-white.svg"
                  alt="info"
                  width={20}
                  height={20}
                  className="dark:block hidden"
                />
              </div>
            </p>

            <p
              className={`!text-sm !lg:text-lg text-black dark:text-white text-center h-[70%] rounded-md flex items-center justify-center `}
            >
              {field.value}
            </p>
          </div>
        );
      })}
      <br />
      <Button
        onClick={() => window.open('https://my.damu.co/register', '_blank')}
        className="!h-11 !text-sm !lg:text-base w-full"
        veryLong
      >
        {t('openNewAccount')}
      </Button>
    </div>
  );
};

export default Common;
