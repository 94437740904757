import React, { FC, PropsWithChildren } from 'react';

const Moonstar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="bg-white flex items-center justify-center py-1 md:py-2 px-1 md:px-4 rounded-md min-w-[70px] md:min-w-[180px] max-w-[190px] md:max-w-[200px] flex-shrink-0">
      <img
        src="/assets/images/landing/moon-star.png"
        alt="moon-star"
        className="h-[12px] w-[12px] md:h-[27px] md:w-[27px] rtl:mr-0 mr-1 md:mr-2"
      />
      <p className="text-[6px] md:text-sm text-light">{children}</p>
    </div>
  );
};

export default Moonstar;
