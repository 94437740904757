export const ibFaqs = [
  {
    question: 'ibFaqQ1',
    answer: 'ibFaqA1',
  },
  {
    question: 'ibFaqQ2',
    answer: 'ibFaqA2',
  },
  {
    question: 'ibFaqQ3',
    answer: 'ibFaqA3',
  },
  {
    question: 'ibFaqQ4',
    answer: 'ibFaqA4',
  },
  {
    question: 'ibFaqQ5',
    answer: 'ibFaqA5',
  },
  {
    question: 'ibFaqQ6',
    answer: 'ibFaqA6',
  },
  {
    question: 'ibFaqQ7',
    answer: 'ibFaqA7',
  },
  {
    question: 'ibFaqQ8',
    answer: 'ibFaqA8',
  },
  {
    question: 'ibFaqQ9',
    answer: 'ibFaqA9',
  },
  {
    question: 'ibFaqQ10',
    answer: 'ibFaqA10',
  },
];

export const wheelFaqs = [
  {
    question: 'wheelFaqQ1',
    answer: 'wheelFaqA1',
  },
  {
    question: 'wheelFaqQ2',
    answer: 'wheelFaqA2',
  },
  {
    question: 'wheelFaqQ3',
    answer: 'wheelFaqA3',
  },
  {
    question: 'wheelFaqQ4',
    answer: 'wheelFaqA4',
  },
  {
    question: 'wheelFaqQ5',
    answer: 'wheelFaqA5',
  },
  {
    question: 'wheelFaqQ6',
    answer: 'wheelFaqA6',
  },
];

export const aBookFaqs = [
  {
    question: 'aBookFaqQ1',
    answer: 'aBookFaqA1',
  },
  { question: 'aBookFaqQ2', answer: 'aBookFaqA2' },
  { question: 'aBookFaqQ3', answer: 'aBookFaqA3' },
  { question: 'aBookFaqQ4', answer: 'aBookFaqA4' },
  { question: 'aBookFaqQ5', answer: 'aBookFaqA5' },
  {
    question: 'aBookFaqQ6',
    answer: 'aBookFaqA6',
  },
  // {
  //   question: 'aBookFaqQ7',
  //   answer: 'aBookFaqA7',
  // },
];

export const safetyFaqs = [
  {
    question: 'safetyFaqQ1',
    answer: 'safetyFaqA1',
  },
  {
    question: 'safetyFaqQ2',
    answer: 'safetyFaqA2',
  },
  {
    question: 'safetyFaqQ3',
    answer: 'safetyFaqA3',
  },
];

export const financialFaqs = [
  {
    question: 'financialFaqQ1',
    answer: 'financialFaqA1',
  },
  {
    question: 'financialFaqQ2',
    answer: 'financialFaqA2',
  },
  {
    question: 'financialFaqQ3',
    answer: 'financialFaqA3',
  },
];

export const referFriendFaqs = [
  {
    question: 'referFriendFaqQ1',
    answer: 'referFriendFaqA1',
  },
  {
    question: 'referFriendFaqQ2',
    answer: 'referFriendFaqA2',
  },
  {
    question: 'referFriendFaqQ3',
    answer: 'referFriendFaqA3',
  },
  {
    question: 'referFriendFaqQ4',
    answer: 'referFriendFaqA4',
  },
  {
    question: 'referFriendFaqQ5',
    answer: 'referFriendFaqA5',
  },
  {
    question: 'referFriendFaqQ6',
    answer: 'referFriendFaqA6',
  },
  {
    question: 'referFriendFaqQ7',
    answer: 'referFriendFaqA7',
  },
];

export const commonFaqs = [
  {
    question: 'homeFaqQ1',
    answer: 'homeFaqA1',
  },
  {
    question: 'homeFaqQ2',
    answer: 'homeFaqA2',
  },
  {
    question: 'homeFaqQ3',
    answer: 'homeFaqA3',
  },
  {
    question: 'homeFaqQ4',
    answer: 'homeFaqA4',
  },
  {
    question: 'homeFaqQ5',
    answer: 'homeFaqA5',
  },
  {
    question: 'homeFaqQ6',
    answer: 'homeFaqA6',
  },
  {
    question: 'homeFaqQ7',
    answer: 'homeFaqA7',
  },
  {
    question: 'homeFaqQ8',
    answer: 'homeFaqA8',
  },
  {
    question: 'homeFaqQ9',
    answer: 'homeFaqA9',
  },
  {
    question: 'homeFaqQ10',
    answer: 'homeFaqA10',
  },
  {
    question: 'homeFaqQ11',
    answer: 'homeFaqA1',
  },
  {
    question: 'homeFaqQ12',
    answer: 'homeFaqA2',
  },
  {
    question: 'homeFaqQ13',
    answer: 'homeFaqA3',
  },
  {
    question: 'homeFaqQ14',
    answer: 'homeFaqA4',
  },
];

export const tradingAccountFaqs = [
  {
    question: 'tradingFaqQ1',
    answer: 'tradingFaqA1',
  },
  {
    question: 'tradingFaqQ2',
    answer: 'tradingFaqA2',
  },
  {
    question: 'tradingFaqQ3',
    answer: 'tradingFaqA3',
  },
  {
    question: 'tradingFaqQ4',
    answer: 'tradingFaqA4',
  },
  {
    question: 'tradingFaqQ5',
    answer: 'tradingFaqA5',
  },
  {
    question: 'tradingFaqQ6',
    answer: 'tradingFaqA6',
  },
  {
    question: 'tradingFaqQ7',
    answer: 'tradingFaqA7',
  },
  {
    question: 'tradingFaqQ8',
    answer: 'tradingFaqA8',
  },
  {
    question: 'tradingFaqQ9',
    answer: 'tradingFaqA9',
  },
  {
    question: 'tradingFaqQ10',
    answer: 'tradingFaqA10',
  },
  {
    question: 'tradingFaqQ11',
    answer: 'tradingFaqA11',
  },
  {
    question: 'tradingFaqQ12',
    answer: 'tradingFaqA12',
  },
  {
    question: 'tradingFaqQ13',
    answer: 'tradingFaqA13',
  },
  {
    question: 'tradingFaqQ14',
    answer: 'tradingFaqA14',
  },
  {
    question: 'tradingFaqQ15',
    answer: 'tradingFaqA15',
  },
  {
    question: 'tradingFaqQ16',
    answer: 'tradingFaqA16',
  },
  {
    question: 'tradingFaqQ17',
    answer: 'tradingFaqA17',
  },
  {
    question: 'tradingFaqQ18',
    answer: 'tradingFaqA18',
  },
  {
    question: 'tradingFaqQ19',
    answer: 'tradingFaqA19',
  },
];
