import { FC } from 'react';
import { COMMONQUESTIONSTAB } from '../../utils/enums';
import { useTranslation } from 'react-i18next';

interface Props {
  activeTab: COMMONQUESTIONSTAB;
  setActiveTab: (tab: COMMONQUESTIONSTAB) => void;
  vertical?: boolean;
}

const Tabs: FC<Props> = ({ activeTab, setActiveTab, vertical }) => {
  const { t } = useTranslation();

  const tabs = [
    { value: COMMONQUESTIONSTAB.tab1, label: t('aBookSystem') },
    { value: COMMONQUESTIONSTAB.tab2, label: t('moneySecurity') },
    { value: COMMONQUESTIONSTAB.tab3, label: t('ipProxy') },
    { value: COMMONQUESTIONSTAB.tab4, label: t('referFriend') },
    { value: COMMONQUESTIONSTAB.tab5, label: t('wheelOfFortune') },
    { value: COMMONQUESTIONSTAB.tab6, label: t('tradingAccounts') },
    { value: COMMONQUESTIONSTAB.tab7, label: t('financialMarkets') },
    { value: COMMONQUESTIONSTAB.tab8, label: t('commonQuestion') },
  ];

  return (
    <div
      className={`flex gap-4 mt-10 w-[570px] lg:w-full overflow-x-auto ${
        vertical ? 'flex-col' : 'flex-row items-center mx-auto'
      }`}
    >
      {tabs.map((tab, index) => (
        <div
          key={index}
          onClick={() => setActiveTab(tab.value)}
          className={`${
            activeTab === tab.value
              ? 'bg-primary text-white'
              : 'bg-transparent text-black dark:text-white'
          } flex ${
            vertical
              ? 'text-xs lg:text-2xl h-9 lg:h-[68px] px-4 lg:px-8'
              : 'justify-center text-sm xl:text-md min-h-9 lg:min-h-[50px] px-2 lg:px-2 text-center'
          }  items-center cursor-pointer rounded-md custom-font-bold`}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
