import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-2 xl:mx-0">
      <div className="container mx-auto py-5">
        <h1 className="text-primary text-center text-[46px] lg:text-[58px] custom-font-bold border-b border-primary">
          {t('privacyPolicy')}
        </h1>
        <div className="py-5">
          <h2 className="text-black dark:text-white custom-font-bold text-[32px] mb-2">
            {t('introduction')}
          </h2>
          <p className="text-black dark:text-white mb-2">
            Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
            suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum
            quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
            posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At
            feugiat sapien varius id.
          </p>
          <p className="text-black dark:text-white mb-5">
            Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat
            mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu
            quis fusce augue enim. Quis at habitant diam at. Suscipit tristique
            risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie
            aliquet sodales id est ac volutpat.
          </p>
          <h6 className="text-black dark:text-white mb-5 text-2xl custom-font-bold">
            Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla
            odio nisl vitae. In aliquet pellentesque aenean hac vestibulum
            turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada
            fringilla.
          </h6>
          <p className="text-black dark:text-white mb-5">
            Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet
            commodo consectetur convallis risus. Sed condimentum enim dignissim
            adipiscing faucibus consequat, urna. Viverra purus et erat auctor
            aliquam. Risus, volutpat vulputate posuere purus sit congue
            convallis aliquet. Arcu id augue ut feugiat donec porttitor neque.
            Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor
            lacus, eget nunc lectus in tellus, pharetra, porttitor.
          </p>
          <h5 className="text-black dark:text-white mb-5 text-lg border-l-2 border-primary pl-4">
            <i>
              "Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim
              mauris id. Non pellentesque congue eget consectetur turpis.
              Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt
              aenean tempus."
            </i>
          </h5>
          <p className="text-black dark:text-white mb-10">
            Tristique odio senectus nam posuere ornare leo metus, ultricies.
            Blandit duis ultricies vulputate morbi feugiat cras placerat elit.
            Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque
            suscipit accumsan. Cursus viverra aenean magna risus elementum
            faucibus molestie pellentesque. Arcu ultricies sed mauris
            vestibulum.
          </p>
          <h2 className="text-black dark:text-white custom-font-bold text-[32px] mb-2">
            Conclusion
          </h2>
          <p className="text-black dark:text-white mb-2">
            Morbi sed imperdiet in ipsum, adipiscing elit dui lectus. Tellus id
            scelerisque est ultricies ultricies. Duis est sit sed leo nisl,
            blandit elit sagittis. Quisque tristique consequat quam sed. Nisl at
            scelerisque amet nulla purus habitasse.
          </p>
          <p className="text-black dark:text-white mb-2">
            Nunc sed faucibus bibendum feugiat sed interdum. Ipsum egestas
            condimentum mi massa. In tincidunt pharetra consectetur sed duis
            facilisis metus. Etiam egestas in nec sed et. Quis lobortis at sit
            dictum eget nibh tortor commodo cursus.
          </p>
          <p className="text-black dark:text-white">
            Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce
            aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id
            morbi eget ipsum. Aliquam senectus neque ut id eget consectetur
            dictum. Donec posuere pharetra odio consequat scelerisque et, nunc
            tortor. Nulla adipiscing erat a erat. Condimentum lorem posuere
            gravida enim posuere cursus diam.
          </p>
        </div>
      </div>
      <div className="border-t border-primary">
        <div className="container mx-auto py-20">
          <h6 className="text-black dark:text-white text-center custom-font-bold text-2xl mb-4">
            {t('wasArticleUseful')}
          </h6>
          <div className="flex items-center gap-5">
            <Button
              containerClass="w-full"
              className="flex-1 w-full bg-black !border-black dark:!border-primary"
              veryLong
            >
              {t('no')}
            </Button>
            <Button containerClass="w-full" className="flex-1 w-full" veryLong>
              {t('yes')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
