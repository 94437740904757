import { FC } from 'react';
import Button from '../../components/Button';
import { ROUTES } from '../../utils/enums';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  tradingAccount?: boolean;
}

const CommonAccount: FC<Props> = ({ tradingAccount }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const accounts = [
    {
      name: 'Standard',
      desc: t('theStandardAccountIsAFavorite'),
      mostWanted: true,
      mostSafest: false,
      href: ROUTES.common,
      icon: '/assets/images/landing/standard.svg',
    },
    {
      name: 'Fit',
      desc: t('theAccountIsDesigned'),
      mostWanted: false,
      mostSafest: false,
      href: ROUTES.common,
      icon: '/assets/images/landing/fit.svg',
    },
    {
      name: 'Crypto',
      desc: t('itIsAnIdealDesitnation'),
      mostWanted: false,
      mostSafest: false,
      href: ROUTES.common,
      icon: '/assets/images/landing/bitcoin.svg',
    },
    {
      name: 'Stock',
      desc: t('theStockAccountIsAn'),
      mostWanted: false,
      mostSafest: false,
      href: ROUTES.common,
      icon: '/assets/images/landing/stocks.svg',
    },
    // {
    //   name: t('protectionAccounts'),
    //   desc: t('itIsConsidered'),
    //   mostWanted: false,
    //   mostSafest: true,
    //   href: ROUTES.protectionAccounts,
    // },
  ];

  return (
    <div
      className={`${tradingAccount ? 'bg-transparent' : 'bg-primary'} py-10`}
    >
      <div className="container mx-auto px-2 xl:px-0">
        {!tradingAccount && (
          <h1 className="text-center text-white text-[32px] xl:text-[58px] custom-font-bold mb-5">
            {t('tradingAccounts')}
          </h1>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
          {accounts.map((account, index) => (
            <div
              key={index}
              className={`border-4 border-white relative p-5 py-10 xl:p-10 flex flex-col bg-primary ${
                index === 4 ? 'xl:col-span-2' : ''
              }`}
            >
              {account.mostWanted && (
                <span className="bg-green text-light custom-font-bold text-2xl absolute top-0 xl:-top-5 px-4 py-1 rounded-md left-1/2 -translate-x-1/2">
                  {t('mostWanted')}
                </span>
              )}
              {account.mostSafest && (
                <span className="bg-green text-light custom-font-bold text-2xl absolute top-0 xl:-top-5 px-4 py-1 rounded-md left-1/2 -translate-x-1/2">
                  {t('mostSafest')}
                </span>
              )}
              <div className="w-[114px] h-[114px] rounded-full bg-white mb-4 flex items-center justify-center">
                <img
                  src={account.icon}
                  alt={account.name}
                  className="h-[67px] w-[67px]"
                />
              </div>
              <h1 className="text-center text-white text-[46px] custom-font-bold">
                {account.name}
              </h1>
              <p className="text-lg text-white text-center mb-4 flex-1">
                {account.desc}
              </p>
              {!tradingAccount && (
                <Button
                  onClick={() => navigate(account.href)}
                  long
                  white
                  className="!w-full"
                >
                  {t('moreDetails')}
                </Button>
              )}
            </div>
          ))}
        </div>
        {!tradingAccount && (
          <div className="my-5 grid grid-cols-2 gap-2 xl:gap-5">
            <Button
              onClick={() =>
                window.open('https://my.damu.co/register', '_blank')
              }
              white
              veryLong
              className="!bg-transparent border-4 text-white border-white h-[48px] xl:h-[76px] text-sm xl:text-base w-full"
            >
              {t('openADemoAccount')}
            </Button>
            <Button
              onClick={() => navigate(ROUTES.tradingAccount)}
              veryLong
              white
              className="h-[48px] xl:h-[76px] text-sm xl:text-base w-full"
            >
              {t('moreDetails')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonAccount;
