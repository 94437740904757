import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  arabicArticles,
  articles,
  spanishArticles,
} from '../../utils/constants';
import { Link } from 'react-router-dom';

interface Props {
  item: {
    id: number;
    name: string;
    details: string;
  };
  vertical?: boolean;
  searchTerm: string;
}

const Tabs: FC<Props> = ({ vertical, item, searchTerm }) => {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const items = useMemo(() => {
    const artis =
      currentLanguage === 'en'
        ? articles
        : currentLanguage === 'es'
        ? spanishArticles
        : currentLanguage === 'ar'
        ? arabicArticles
        : articles;

    const arts = artis.filter((i) => i.id !== item.id);
    if (searchTerm === '') {
      return arts;
    } else {
      return arts.filter((i) =>
        i.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }
  }, [currentLanguage, item.id, searchTerm]);

  return (
    <div
      className={`flex gap-4 mt-10 w-[570px] lg:w-full overflow-x-auto ${
        vertical ? 'flex-col' : 'flex-row items-center'
      }`}
    >
      {items.map((item, index) => (
        <Link
          to={`/education/articles/${item.id}`}
          key={index}
          className={`${
            vertical
              ? 'bg-transparent text-black dark:text-white'
              : 'bg-primary text-white'
          } h-9 lg:h-[68px] flex ${
            vertical ? '' : 'justify-center'
          }  items-center px-4 lg:px-8 cursor-pointer rounded-md text-xs lg:text-xl 2xl:text-2xl custom-font-bold hover:bg-primary hover:text-white transition-all`}
        >
          {item.name}
        </Link>
      ))}
    </div>
  );
};

export default Tabs;
