import { useTranslation } from 'react-i18next';
import FAQs from '../../components/FAQs';
import CTA from '../../containers/Home/CTA';
import CommonAccount from '../../containers/Home/CommonAccount';
import GetStarted from '../../containers/Home/GetStarted';
import Hero from '../../containers/Home/Hero';
import Leaderboard from '../../containers/Home/Leaderboard';
import MakingProfits from '../../containers/Home/MakingProfits';
import YourChoice from '../../containers/Home/YourChoice';
import { commonFaqs } from '../../utils/faqs';
import { ROUTES } from '../../utils/enums';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Hero />
      {/* <Leaderboard /> */}
      <br />
      <br />
      <br />
      <br />
      <YourChoice />
      <CommonAccount />
      <GetStarted />
      <CTA />
      <MakingProfits />
      <FAQs
        faqs={commonFaqs.slice(0, 3)}
        label={t('commonQuestionsFaq')}
        link={ROUTES.commonQuestionCommon}
      />
    </>
  );
};

export default Home;
