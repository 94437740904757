import { FC, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';

interface Props {
  src: string;
  hoverSrc: string;
  alt: string;
  className: string;
}

const AnimatedImage: FC<Props> = ({ src, hoverSrc, alt, className }) => {
  const [isHovered, setIsHovered] = useState(false);

  const { transform } = useSpring({
    transform: isHovered ? 'scale(1.3)' : 'scale(1)',
    config: { tension: 100, friction: 10 },
  });

  return (
    <animated.img
      src={isHovered ? hoverSrc : src}
      alt={alt}
      className={className}
      style={{ transform }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
};

export default AnimatedImage;
