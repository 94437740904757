import { FC } from 'react';
import { animated } from '@react-spring/web';

interface ChoiceProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const Choice: FC<ChoiceProps> = ({ label, isActive, onClick }) => {
  return (
    <animated.div
      className={`flex items-center h-[68px] hover:scale-105 transition-[300] cursor-pointer px-3 rounded-xl relative ${
        isActive ? 'bg-primary text-dark' : 'text-light dark:text-dark'
      }`}
      onClick={onClick}
    >
      <img
        src={
          isActive
            ? '/assets/images/landing/star-white.svg'
            : '/assets/images/landing/star-purple.svg'
        }
        alt="star"
        className="h-6 w-6 mr-3"
      />
      <p className="text-2xl custom-font-bold">{label}</p>
    </animated.div>
  );
};

export default Choice;
