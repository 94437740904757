import { useState } from 'react';
import { ABOUTTAB } from '../../utils/enums';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';

const RateYourExperience = () => {
  const [activeTab, setActiveTab] = useState(ABOUTTAB.tab1);

  const { t } = useTranslation();

  const reviews = [
    {
      name: t('customerCentricity'),
      review: t('ourCustomersAreAt'),
      stars: 2,
    },
    {
      name: t('customerCentricity'),
      review: t('ourCustomersAreAt'),
      stars: 4,
    },
    {
      name: t('customerCentricity'),
      review: t('ourCustomersAreAt'),
      stars: 4,
    },
    {
      name: t('customerCentricity'),
      review: t('ourCustomersAreAt'),
      stars: 4,
    },
  ];

  return (
    <div className="container mx-auto my-10 lg:mb-20 lg:px-5">
      <h1 className="text-center text-black dark:text-white custom-font-bold text-[46px] lg:text-[64px] mb-5 lg:mb-10">
        {t('rateYourExp')}
      </h1>
      <div className="flex items-center justify-center gap-5 mb-10">
        <div
          onClick={() => setActiveTab(ABOUTTAB.tab1)}
          className={`${
            activeTab === ABOUTTAB.tab1
              ? 'bg-primary button-long--hexagon text-white'
              : 'text-black dark:text-white'
          } h-[68px] w-[157px] text-2xl flex items-center justify-center cursor-pointer hover:bg-primary hover:text-white transition-[300]`}
        >
          {t('customer')}
        </div>
        <div
          onClick={() => setActiveTab(ABOUTTAB.tab2)}
          className={`${
            activeTab === ABOUTTAB.tab2
              ? 'bg-primary button-long--hexagon text-white'
              : 'text-black dark:text-white'
          } h-[68px] w-[157px] text-2xl flex items-center justify-center cursor-pointer hover:bg-primary hover:text-white transition-[300]`}
        >
          {t('services')}
        </div>
      </div>
      <div className="grid lg:grid-cols-4 gap-10 px-2 lg:px-0">
        {reviews.map((review, index) => (
          <div
            className="flex flex-col items-center border-b border-primary pb-5"
            key={index}
          >
            <h1 className="text-primary mb-5 text-2xl custom-font-bold">
              {review.name}
            </h1>
            <p className="text-black dark:text-white text-sm mb-5 text-center">
              {review.review}
            </p>
            <div className="flex items-center justify-center gap-3">
              {Array.from({ length: review.stars }).map((_, index) => (
                <img
                  key={index}
                  className="h-6 w-6"
                  src="/assets/icons/star-filled.svg"
                  alt="star"
                />
              ))}
              {Array.from({ length: 5 - review.stars }).map((_, index) => (
                <img
                  key={index}
                  className="h-6 w-6"
                  src="/assets/icons/star.svg"
                  alt="star"
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-10 flex flex-col lg:flex-col items-center justify-center px-2 lg:px-0">
        <p className="text-2xl text-black dark:text-white mr-3 text-center lg:text-left mb-5 lg:mb-0">
          {t('weEvaluatedHundredsOf')}
          <br className="hidden lg:block" />
          {t('experienceWithUs')}
        </p>
        <Button long className="!px-20">
          {t('rateNow')}
        </Button>
      </div>
    </div>
  );
};

export default RateYourExperience;
