import { useMemo, useState } from 'react';
import Search from '../../../../components/Search';
import { useTranslation } from 'react-i18next';
import {
  arabicArticles,
  articles,
  spanishArticles,
} from '../../../../utils/constants';
import { useParams } from 'react-router-dom';
import OtherArticles from '../../../../containers/Education/OtherArticles';

const SingleArticle = () => {
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  const currentLanguage = i18n.language;

  let { slug } = useParams();

  const article = useMemo(() => {
    switch (currentLanguage) {
      case 'en':
        return articles.find((i) => i.id === +(slug || 1));
      case 'es':
        return spanishArticles.find((i) => i.id === +(slug || 1));
      case 'ar':
        return arabicArticles.find((i) => i.id === +(slug || 1));

      default:
        return articles.find((i) => i.id === +(slug || 1));
    }
  }, [currentLanguage, slug]);

  return !article ? (
    <></>
  ) : (
    <>
      <div className="container mx-auto pt-5 lg:py-5 hidden lg:flex flex-col px-2 lg:px-5">
        <div className="flex gap-10">
          <div className="w-[304px] 2xl:w-[354px] flex-shrink-0">
            <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <OtherArticles item={article} vertical searchTerm={searchTerm} />
          </div>
          <div className="flex-1">
            <div className="mx-2 lg:mx-0">
              <div className="container mx-auto py-5">
                <h1 className="text-primary text-center text-[46px] lg:text-[58px] custom-font-bold border-b border-primary">
                  {article.name}
                </h1>
                <div
                  className="dark:text-white text-black single-article"
                  dangerouslySetInnerHTML={{ __html: article.details }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto pt-5 lg:py-5 flex lg:hidden flex-col px-2 lg:px-0">
        <h1 className="text-[32px] text-center lg:text-left py-5 lg:py-0 lg:text-[64px] text-black dark:text-white order-2 lg:order-1">
          {t('articles')}
        </h1>
        <div className="order-1 lg:order-2">
          <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
        <div className="order-3 overflow-x-auto">
          <OtherArticles item={article} searchTerm={searchTerm} />
        </div>
        <div className="order-4">
          <div className="flex-1">
            <div className="mx-2 lg:mx-0">
              <div className="container mx-auto py-5">
                <h1 className="text-primary text-center text-[46px] lg:text-[58px] custom-font-bold border-b border-primary">
                  {article.name}
                </h1>
                <div
                  className="dark:text-white text-black single-article"
                  dangerouslySetInnerHTML={{ __html: article.details }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleArticle;
