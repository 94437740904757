import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CTA = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-primary relative xl:grid grid-cols-2 h-[431px] xl:h-[558px] overflow-hidden">
      <div className="flex flex-col h-full xl:block justify-center items-center rtl:xl:pr-20 rtl:xl:pl-0 xl:pl-20">
        <div className="flex flex-col justify-center items-center xl:items-start h-full">
          <div className="flex items-center mb-4">
            <img
              className="mr-4"
              src="/assets/images/landing/cta-icon.svg"
              alt="cta"
            />
            <span className="text-white custom-font-bold text-[46px] xl:text-[58px] ">
              MetaTrader 5
            </span>
          </div>
          <p className="text-white text-lg w-full px-2 xl:px-0 xl:w-2/3 text-center xl:text-left">
            {t('downloadTheAppToManage')}
          </p>
          <div className="mt-5 xl:mt-10">
            <p className="text-lg text-white text-center xl:rtl:text-right xl:text-left">
              {t('getTheApp')}
            </p>
            <div className="flex ">
              <Link
                to={
                  'https://download.mql5.com/cdn/mobile/mt5/android?server=Damu-Fly%20your%20trade4'
                }
                target="_blank"
              >
                <img
                  src="/assets/images/landing/google-store.svg"
                  alt="google-store"
                  className="mr-2 rtl:ml-2 rtl:mr-0 hover:scale-110 transition-[300]"
                />
              </Link>
              <Link
                to={
                  'https://download.mql5.com/cdn/mobile/mt5/ios?server=Damu-Fly%20your%20trade'
                }
                target="_blank"
              >
                <img
                  className="mr-2 rtl:ml-2 rtl:mr-0 hover:scale-110 transition-[300]"
                  src="/assets/images/landing/app-store.svg"
                  alt="google-store"
                />
              </Link>
              <Link
                to={
                  'https://download.mql5.com/cdn/web/damu.ltd/mt5/damu5setup.exe'
                }
                target="_blank"
              >
                <img
                  className="hover:scale-110 transition-[300]"
                  src="/assets/images/landing/windows.svg"
                  alt="google-store"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="hidden xl:grid grid-cols-2"
        style={{ background: 'url(/assets/images/landing/cta.svg)' }}
      >
        <div className="relative">
          <img
            className="absolute -top-[100px] hover:top-[100px] transition-all duration-300 left-1/2 -translate-x-1/2"
            src="/assets/images/landing/cta-phone.svg"
            alt="phone"
          />
        </div>
        <div className="relative">
          <img
            className="absolute -top-[100px] hover:top-[100px] transition-all duration-300 left-1/2 -translate-x-1/2"
            src="/assets/images/landing/cta-laptop.svg"
            alt="phone"
          />
          <img
            className="absolute -bottom-[100px] hover:bottom-[100px] transition-all duration-300 left-1/2 -translate-x-1/2"
            src="/assets/images/landing/cta-tablet.svg"
            alt="phone"
          />
        </div>
      </div>
    </div>
  );
};

export default CTA;
