import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { ROUTES } from '../../utils/enums';
import AnimatedImage from './AnimatedImage';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import PDFViewer from '../../components/PDFViewer';
import { useState } from 'react';

const MakingProfits = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState('');

  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const referAFriendUrl = '../../assets/docs/Refer-a-Friend.pdf';
  const ibProxyUrl =
    '../../assets/docs/Business-partners-terms-and-conditions.pdf';

  return (
    <div className="py-10 xl:py-20 container mx-auto">
      <h1 className="text-primary text-[24px] xl:text-[40px] custom-font-bold text-center ">
        {t('startMakingYourProfit')}
      </h1>
      <div className="my-10 grid grid-cols-1 xl:grid-cols-2 gap-5 px-2 xl:px-0">
        <div className="border-4 border-primary bg-white p-5 flex flex-col">
          <div className="bg-primary flex items-center justify-center mb-5">
            <img
              src="/assets/images/middleman/refer-friend.png"
              alt="refer-friend"
              className="w-full"
            />
          </div>
          <h1 className="text-center text-black text-[46px] custom-font-bold mb-5">
            {t('referFriend')}
          </h1>
          <p className="text-black text-lg mb-5 text-center">
            {t('theReferrer')}
          </p>
          <div className="hidden xl:block">
            <Slider {...settings}>
              <div className="bg-primary w-full aspect-square !flex flex-col items-center justify-center px-4 border-2 border-r-0 border-primary">
                <img
                  src="/assets/images/middleman/invitation.svg"
                  alt="damu"
                  className="h-[80px] w-[80px] mb-5"
                />
                <p className="text-white custom-font-bold text-2xl text-center">
                  {t('registeredInvitation')}
                </p>
              </div>
              <div className="bg-white w-full aspect-square !flex flex-col items-center justify-center px-4 border-2 border-r-0 border-primary">
                <img
                  src="/assets/images/middleman/verification.svg"
                  alt="damu"
                  className="h-[80px] w-[80px] mb-5"
                />
                <p className="text-primary custom-font-bold text-2xl text-center">
                  {t('kycVerification')}
                </p>
              </div>
              <div className="bg-white w-full aspect-square !flex flex-col items-center justify-center px-4 border-2 border-r-0 border-primary">
                <img
                  src="/assets/images/middleman/first-deposit.svg"
                  alt="damu"
                  className="h-[80px] w-[80px] mb-5"
                />
                <p className="text-primary custom-font-bold text-2xl text-center">
                  {t('theFirstDeposit')}
                </p>
              </div>
              <div className="bg-white w-full aspect-square !flex flex-col items-center justify-center px-4 border-2 border-r-0 border-primary">
                <img
                  src="/assets/images/middleman/second-deposit.svg"
                  alt="damu"
                  className="h-[80px] w-[80px] mb-5"
                />
                <p className="text-primary custom-font-bold text-2xl text-center">
                  {t('repeatDeposit')}
                </p>
              </div>
              <div className="bg-white w-full aspect-square !flex flex-col items-center justify-center px-4 border-2 border-r-0 border-primary">
                <img
                  src="/assets/images/middleman/lot.svg"
                  alt="damu"
                  className="h-[80px] w-[80px] mb-5"
                />
                <p className="text-primary custom-font-bold text-2xl text-center">
                  {t('consumedLot')}
                </p>
              </div>
            </Slider>
          </div>
          <Button
            onClick={() => navigate(ROUTES.referFriend)}
            className="w-full !p-0 my-5"
            long
          >
            {t('showMore')}
          </Button>
          <div className="flex items-center justify-center">
            <img
              src="/assets/images/landing/file.svg"
              alt="file"
              className="h-6 w-6 mr-3"
            />
            <p
              onClick={() => setShowModal('refer')}
              className="text-black font-custom-medium"
            >
              {t('termsAndConditionFile')}
            </p>
          </div>
        </div>
        <div className="border-4 border-primary bg-white p-5 flex flex-col">
          <div className="bg-primary flex items-center justify-center mb-5">
            <img
              src="/assets/images/middleman/ib-proxy.png"
              alt="refer-friend"
              className="w-full"
            />
          </div>
          <h1 className="text-black text-[46px] custom-font-bold mb-5">
            {t('IpProxy')}
          </h1>
          <p className="text-black text-lg mb-5 text-left xl:text-center">
            {t('getUpto')}
          </p>
          <div className="xl:grid grid-cols-2 gap-4">
            <Button
              onClick={() => navigate(ROUTES.ibProxy)}
              className="w-full !p-0 my-5"
              long
            >
              {t('showMore')}
            </Button>
            <div className="xl:flex items-center hidden">
              <img
                src="/assets/images/landing/file.svg"
                alt="file"
                className="h-6 w-6 mr-3"
              />
              <p
                onClick={() => setShowModal('ib')}
                className="text-black font-custom-medium"
              >
                {t('termsAndConditionFile')}
              </p>
              {/* <PDFViewer url={'assets/docs/refer-a-friend.pdf'} /> */}
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <h1 className="text-black text-[32px] xl:text-[46px] custom-font-bold text-center dark:text-white">
        {t('startDepositing')}
      </h1>
      <div className="flex flex-col xl:flex-row items-center gap-10 xl:gap-4 my-10 mt-28 justify-center">
        <AnimatedImage
          src="/assets/images/landing/visa.svg"
          hoverSrc="/assets/images/landing/visa-hover.svg"
          alt="visa"
          className="h-[64px] w-[212px] xl:w-[190px]"
        />
        <AnimatedImage
          src="/assets/images/landing/master-card.svg"
          hoverSrc="/assets/images/landing/master-card-hover.svg"
          alt="master-card"
          className="h-[85px] w-[300px] xl:w-[440px]"
        />
        <AnimatedImage
          src="/assets/images/landing/crypto.svg"
          hoverSrc="/assets/images/landing/crypto-hover.svg"
          alt="crypto"
          className="h-[92px] w-[300px] xl:w-[241px]"
        />
        <AnimatedImage
          src="/assets/images/landing/perfect-money.svg"
          hoverSrc="/assets/images/landing/perfect-money-hover.svg"
          alt="perfect-money"
          className="h-[78px] w-[300px] xl:w-[389px]"
        />
      </div>
      {showModal && (
        <PDFViewer
          url={showModal === 'ib' ? ibProxyUrl : referAFriendUrl}
          onClose={() => setShowModal('')}
        />
      )}
    </div>
  );
};

export default MakingProfits;

const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      className="absolute -right-3 top-1/2 translate-y-1/4 -mt-[50px] flex h-[56px] w-[56px] items-center justify-center rounded-full z-10"
      onClick={onClick}
    >
      <img
        src="/assets/icons/arrow-right.svg"
        alt="right-arrow"
        className="h-8 w-8"
      />
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      className="absolute -left-3 top-1/2 translate-y-1/4 -mt-[50px] flex h-[56px] w-[56px] items-center justify-center rounded-full z-10"
      onClick={onClick}
    >
      <img
        src="/assets/icons/arrow-left.svg"
        alt="left-arrow"
        className="h-8 w-8"
      />
    </div>
  );
};
