import { useCallback, useEffect, useState } from 'react';
import { HOMECHOICES } from '../../utils/enums';
import { useSpring, animated } from '@react-spring/web';
import Choice from './ChoiceItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import YourChoiceImg1 from '../../assets/images/your-choice-1.png';
import YourChoiceImg2 from '../../assets/images/your-choice-2.png';
import YourChoiceImg3 from '../../assets/images/your-choice-3.png';
import YourChoiceImg4 from '../../assets/images/your-choice-4.png';
import YourChoiceImg5 from '../../assets/images/your-choice-6.png';
import YourChoiceImg6 from '../../assets/images/your-choice-5.png';

const YourChoice = () => {
  const [activeTab, setActiveTab] = useState<HOMECHOICES>(HOMECHOICES.tab1);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
  };

  const choices = [
    {
      label: t('aBookBroker'),
      value: HOMECHOICES.tab1,
      rotation: 0,
      image: YourChoiceImg1,
    },
    // {
    //   label: t('riskManagement'),
    //   value: HOMECHOICES.tab2,
    //   rotation: 180,
    // },
    {
      label: t('priceDifferenceThat'),
      value: HOMECHOICES.tab3,
      rotation: 0,
      image: YourChoiceImg2,
    },
    {
      label: t('technicalSupport'),
      value: HOMECHOICES.tab4,
      rotation: 0,
      image: YourChoiceImg3,
    },
    {
      label: t('providingMore'),
      value: HOMECHOICES.tab5,
      rotation: 0,
      image: YourChoiceImg4,
    },
    {
      label: t('depositFreely'),
      value: HOMECHOICES.tab6,
      rotation: 0,
      image: YourChoiceImg5,
    },
    {
      label: t('leverageUpto'),
      value: HOMECHOICES.tab7,
      rotation: 0,
      image: YourChoiceImg6,
    },
  ];

  const iconSpring = useSpring({
    opacity: activeTab ? 1 : 0,
    config: { tension: 100, friction: 30 },
  });

  const updateTab = useCallback((index: number) => {
    setCurrentIndex(index);
    setActiveTab(choices[index].value);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % choices.length;
        setActiveTab(choices[nextIndex].value);
        return nextIndex;
      });
    }, 2500);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleTabClick = (value: HOMECHOICES) => {
    const index = choices.findIndex((choice) => choice.value === value);
    updateTab(index);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        const newIndex = (currentIndex - 1 + choices.length) % choices.length;
        updateTab(newIndex);
      } else if (event.key === 'ArrowDown') {
        const newIndex = (currentIndex + 1) % choices.length;
        updateTab(newIndex);
      }
    },
    [currentIndex, updateTab],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="container mx-auto mb-5 xl:mb-20 z-10 relative">
      <h1 className="text-[32px] xl:text-5xl custom-font-bold text-center mb-10 text-light dark:text-dark">
        {t('whyDamuChoice')}
      </h1>
      <div className="hidden xl:grid grid-cols-2 gap-10">
        <div className="flex flex-col gap-1">
          {choices.map((choice, index) => (
            <Choice
              key={index}
              label={choice.label}
              isActive={activeTab === choice.value}
              onClick={() => handleTabClick(choice.value)}
            />
          ))}
        </div>
        <div>
          <div className="h-full w-full xl:w-[88%] bg-primary flex items-center justify-center relative overflow-hidden aspect-video">
            <animated.img
              src={
                activeTab === HOMECHOICES.tab1
                  ? YourChoiceImg1
                  : activeTab === HOMECHOICES.tab3
                  ? YourChoiceImg2
                  : activeTab === HOMECHOICES.tab4
                  ? YourChoiceImg3
                  : activeTab === HOMECHOICES.tab5
                  ? YourChoiceImg4
                  : activeTab === HOMECHOICES.tab6
                  ? YourChoiceImg5
                  : YourChoiceImg6
              }
              alt="choice"
              className="absolute inset-0 w-full object-cover]"
              style={{ ...iconSpring, objectPosition: '0px -20px' }}
            />
          </div>
        </div>
      </div>
      <div className="flex xl:hidden w-screen p-2">
        <div className="w-full h-full">
          <Slider {...settings}>
            {choices.map((choice, index) => (
              <div
                key={index}
                className="bg-primary relative h-auto !flex !flex-col justify-between items-center p-5"
              >
                <img
                  src={choice.image}
                  alt={choice.label}
                  className="w-full rounded-xl mb-4"
                />
                <p className="w-full bg-white text-primary text-2xl custom-font-bold p-2 rounded-xl text-center">
                  {choice.label}
                </p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default YourChoice;

const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      className="absolute right-[10px] top-1/2 -translate-y-1/2 -mt-[50px] flex h-[56px] w-[56px] items-center justify-center rounded-full z-10 bg-white"
      onClick={onClick}
    >
      <img src="/assets/icons/arrow-right.svg" alt="right-arrow" />
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      className="absolute left-[10px] top-1/2 -translate-y-1/2 -mt-[50px] flex h-[56px] w-[56px] items-center justify-center rounded-full z-10 bg-white"
      onClick={onClick}
    >
      <img src="/assets/icons/arrow-left.svg" alt="left-arrow" />
    </div>
  );
};
