import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-primary relative">
      <div className="h-[525px] w-full relative">
        <img
          src="/assets/images/middleman/ip-proxy.svg"
          alt="ip-proxy"
          className="object-cover absolute inset-0 w-full h-[525px] "
        />
        <video
          className="h-[525px] !w-full object-cover xl:object-fill absolute inset-0"
          style={{ mixBlendMode: 'plus-lighter' }}
          autoPlay
          loop
          controlsList="nofullscreen"
          playsInline
        >
          <source
            src="/assets/images/middleman/animation.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="inset-0 absolute flex items-center justify-center">
        <h1 className="text-white text-[64px] custom-font-bold">
          {t('middleman')}
        </h1>
      </div>
    </div>
  );
};

export default Hero;
