import { FC, useEffect, useState } from 'react';
import { FINANCIALMARKETSTAB } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../utils/helpers';

interface Props {
  tab: string | null;
}

const Markets: FC<Props> = ({ tab }) => {
  const [activeTab, setActiveTab] = useState<string>(FINANCIALMARKETSTAB.tab1);

  const { t } = useTranslation();

  const tabs = [
    {
      value: FINANCIALMARKETSTAB.tab1,
      name: t('commodities'),
      bigChange: false,
    },
    {
      value: FINANCIALMARKETSTAB.tab2,
      name: t('stocks'),
      bigChange: false,
    },
    {
      value: FINANCIALMARKETSTAB.tab3,
      name: t('indices'),
      bigChange: false,
    },
    {
      value: FINANCIALMARKETSTAB.tab4,
      name: t('forex'),
      bigChange: true,
    },
    {
      value: FINANCIALMARKETSTAB.tab5,
      name: t('metals'),
      bigChange: false,
    },
  ];

  const data = [
    {
      field: 'AUDUSD',
      value: ['0.65707', '0.65707', '1 PIP', '10$'],
    },
    {
      field: 'EURUSD',
      value: ['0.65707', '0.65707', '1 PIP', '10$'],
    },
    {
      field: 'GBPUSD',
      value: ['0.65707', '0.65707', '1 PIP', '10$'],
    },
    {
      field: 'USDCAD',
      value: ['0.65707', '0.65707', '1 PIP', '10$'],
    },
    {
      field: 'USDJPY',
      value: ['0.65707', '0.65707', '1 PIP', '10$'],
    },
  ];

  useEffect(() => {
    if (tab) {
      setActiveTab(capitalizeFirstLetter(tab));
    }
  }, [tab]);

  return (
    <div className="bg-white dark:bg-black lg:bg-primary dark:lg:bg-primary py-10 mb-10">
      <div className="container mx-auto overflow-hidden">
        <h1 className="text-center text-[32px] text-black dark:text-white dark:lg:text-white lg:text-white custom-font-bold mb-10 px-5 lg:px-0 ">
          {t('chooseTheFinancialMarket')}
        </h1>
        <div className="grid grid-cols-2 lg:grid-cols-5 mb-10 gap-5 lg:gap-1 px-2 lg:px-0">
          {tabs.map((tab, index) => (
            <div
              onClick={() => setActiveTab(tab.value)}
              className={`text-center text-2xl w-full h-[79px] lg:h-[60px] custom-font-bold cursor-pointer flex items-center justify-center rounded-lg relative hover:bg-primary dark:hover:bg-white hover:text-white dark:hover:text-primary transition-[300] ${
                activeTab === tab.value
                  ? 'bg-primary text-white dark:bg-white dark:text-primary'
                  : 'text-black dark:text-white'
              } ${index === tabs.length - 1 ? 'col-span-2 lg:col-span-1' : ''}`}
              key={index}
            >
              {tab.name}
              {tab.bigChange && (
                <span className="bg-green text-black absolute bottom-[90%] h-11 rounded-lg hidden lg:flex items-center justify-center px-2">
                  {t('bigChange')}
                </span>
              )}
            </div>
          ))}
        </div>
        <div className="w-full overflow-x-auto">
          <div className="my-20 bg-primary lg:bg-transparent py-10 lg:py-0 w-[800px] lg:w-full overflow-x-auto px-2 lg:px-0">
            <div className="grid grid-cols-5 h-[65px] items-center border-b border-white gap-2">
              <p className="text-2xl text-white custom-font-bold">
                {t('name')}
              </p>
              <p className="text-2xl text-white custom-font-bold text-center">
                {t('buying')}
              </p>
              <p className="text-2xl text-white custom-font-bold text-center">
                {t('sale')}
              </p>
              <p className="text-2xl text-white custom-font-bold text-center">
                {t('priceDifference')}
              </p>
              <p className="text-2xl text-white custom-font-bold text-center">
                {t('pointPrice')}
              </p>
            </div>
            {data.map((field, index) => {
              return (
                <div
                  key={index}
                  className="grid grid-cols-5 h-[65px] items-center border-b border-white gap-2"
                >
                  <p className="text-2xl text-white custom-font-bold flex justify-between items-center">
                    {field.field}
                    <img
                      src="/assets/icons/info-white.svg"
                      alt="info"
                      height={20}
                      width={20}
                    />
                  </p>
                  {field.value.map((value, index) => (
                    <div className="flex items-center justify-center">
                      <p
                        key={index}
                        className={`text-lg text-center ${
                          index === 0 || index === 1
                            ? 'text-green'
                            : 'text-white'
                        }`}
                      >
                        {value}
                      </p>
                      {index === 0 || index === 1 ? (
                        <img
                          src="/assets/icons/arrow-green.svg"
                          alt="arrow"
                          height={20}
                          width={20}
                          className="ml-2"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Markets;
