import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';

const FindOut = () => {
  const { t } = useTranslation();

  const types = [
    {
      number: 1,
      text: t('fillInYourInformation'),
    },
    {
      number: 2,
      text: t('verifyTheEmail'),
    },
    {
      number: 3,
      text: t('enterYourAccountInformation'),
    },
    {
      number: 4,
      text: t('liveTheRealTradingExperience'),
    },
  ];

  return (
    <div className="bg-primary mb-20 px-2 lg:px-0">
      <div className="container mx-auto py-20 flex flex-col items-center">
        <h1 className="text-center text-white custom-font-medium text-2xl lg:text-[36px] lg:leading-[50px] mb-10">
          {t('findOutForYourself')}
        </h1>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mb-10 items-start">
          {types.map((type, index) => (
            <div
              className="flex flex-col items-center justify-center"
              key={index}
            >
              <div className="h-[88px] w-[78px] bg-white rounded-md text-[46px] text-primary custom-font-bold flex items-center justify-center mb-3">
                {type.number}
              </div>
              <p className="text-white text-center text-sm lg:text-2xl custom-font-bold w-full">
                {type.text}
              </p>
            </div>
          ))}
        </div>
        <Button
          onClick={() => window.open('https://my.damu.co/register', '_blank')}
          white
          className="w-[290px] h-[71px] text-2xl"
          long
        >
          {t('openNewAccount')}
        </Button>
      </div>
    </div>
  );
};

export default FindOut;
