import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Table from '../../../containers/Middleman/Table';
import { useState } from 'react';
import PDFViewer from '../../../components/PDFViewer';

const IpProxy = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const url = '../../assets/docs/Business-partners-terms-and-conditions.pdf';

  return (
    <div>
      <div className="container mx-auto px-2 xl:px-0">
        <div className="border-4 border-primary bg-white p-5 flex flex-col">
          <div className="bg-primary flex items-center justify-center mb-5">
            <img
              src="/assets/images/middleman/ib-proxy.png"
              alt="refer-friend"
              className="w-full"
            />
          </div>
          <h1 className="text-black text-[46px] custom-font-bold mb-5">
            {t('ipProxy')}
          </h1>
          <p className="text-black text-xl mb-5 text-left">{t('getUpto')}</p>
          <div className="flex items-center">
            <Button
              onClick={() =>
                window.open('https://my.damu.co/partner/register', '_blank')
              }
              className="w-full xl:w-[336px] !p-0 my-5 xl:mr-10"
              veryLong
            >
              {t('openNewAccount')}
            </Button>
            <div className="xl:flex items-center hidden ">
              <img
                src="/assets/images/landing/file.svg"
                alt="file"
                className="h-6 w-6 mr-3"
              />
              <p
                onClick={() => setShowModal(true)}
                className="text-black font-custom-medium"
              >
                {t('termsAndConditionFile')}
              </p>
            </div>
          </div>
        </div>
        <Table />
      </div>
      {showModal && <PDFViewer url={url} onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default IpProxy;
