import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import { ThemeProvider } from './context/ThemeContext';
import Cursor from './components/Cursor';
import ScrollToTop from './components/ScrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider>
        <ScrollToTop />
        <App />
        <Cursor />
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
);
