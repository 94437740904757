import { useCallback, useEffect, useState } from 'react';
import { useSpring, useTransition, animated } from '@react-spring/web';
import { HOMESTARTED } from '../../utils/enums';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

const GetStarted = () => {
  const { t } = useTranslation();

  const choices = [
    {
      label: t('browserTheTypes'),
      value: HOMESTARTED.tab1,
      values: [
        {
          name: 'Standard',
          icon: '/assets/images/landing/standard.svg',
        },
        {
          name: 'Fit',
          icon: '/assets/images/landing/fit.svg',
        },
        {
          name: 'Crypto',
          icon: '/assets/images/landing/bitcoin.svg',
        },
        {
          name: 'Stock',
          icon: '/assets/images/landing/stocks.svg',
        },
      ],
    },
    {
      label: t('openTheAccount'),
      value: HOMESTARTED.tab2,
      values: [],
    },
    {
      label: t('makeADeposit'),
      value: HOMESTARTED.tab3,
      values: [],
    },
    {
      label: t('startADifferentTrading'),
      value: HOMESTARTED.tab4,
      values: [],
    },
    {
      label: t('enjoyAnEasyCustomer'),
      value: HOMESTARTED.tab5,
      values: [],
    },
  ];

  const [activeTab, setActiveTab] = useState(choices[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const updateTab = useCallback((index: number) => {
    setCurrentIndex(index);
    setActiveTab(choices[index]);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % choices.length;

        setActiveTab(choices[nextIndex]);
        return nextIndex;
      });
    }, 2500);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const handleTabClick = (value: HOMESTARTED) => {
    const index = choices.findIndex((choice) => choice.value === value);
    updateTab(index);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
  };

  const fadeStyles = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    reset: true,
  });

  const transitions = useTransition(choices, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    keys: (item) => item.value,
  });

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        const newIndex = (currentIndex - 1 + choices.length) % choices.length;
        updateTab(newIndex);
      } else if (event.key === 'ArrowDown') {
        const newIndex = (currentIndex + 1) % choices.length;
        updateTab(newIndex);
      }
    },
    [currentIndex, updateTab],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="container mx-auto my-20">
      <h1 className="text-[32px] xl:text-[46px] text-center xl:text-left custom-font-bold mb-10 text-light dark:text-dark">
        {t('howToGetStarted')}
      </h1>
      <div className="hidden xl:grid grid-cols-2 gap-10 overflow-visible">
        <div className="flex flex-col gap-3">
          {transitions((styles, choice, _, index) => (
            <animated.div
              className="flex items-center cursor-pointer group"
              style={styles}
              onClick={() => handleTabClick(choice.value)}
              key={choice.value}
            >
              <div
                className={`button--hexagon w-[91px] group-hover:scale-110 transition-[300] h-[91px] flex items-center justify-center flex-shrink-0 mr-5 rtl:ml-5 text-[46px] text-white custom-font-bold ${
                  choice.value === activeTab.value ? 'bg-primary' : 'bg-gray'
                }`}
              >
                {index + 1}
              </div>
              <p
                className={`text-2xl custom-font-bold ${
                  choice.value === activeTab.value
                    ? 'text-primary'
                    : 'text-gray'
                }`}
              >
                {choice.label}
              </p>
            </animated.div>
          ))}
        </div>
        <div className="h-full w-full relative overflow-visible flex items-center justify-center">
          <img
            src="/assets/images/landing/home-started-bg.svg"
            alt="choice"
            className="absolute inset-0 -top-0 -left-2"
          />
          <animated.div style={fadeStyles}>
            {activeTab.value === HOMESTARTED.tab1 && (
              <div className="grid grid-cols-2 gap-2 items-center h-full">
                {activeTab.values.map((value, index) => (
                  <div
                    key={index}
                    className="relative p-4 bg-primary aspect-square w-[160px] border-4 border-white flex flex-col items-center justify-center"
                  >
                    <div className="w-[60px] h-[60px] rounded-full bg-white mb-4 flex items-center justify-center">
                      <img
                        src={value.icon}
                        alt={value.name}
                        className="h-[46px] w-[46px]"
                      />
                    </div>
                    <h1 className="text-center text-green text-xl custom-font-bold">
                      {value.name}
                    </h1>
                  </div>
                ))}
              </div>
            )}
            {activeTab.value === HOMESTARTED.tab2 && (
              <div className="h-[300px] w-[300px] bg-primary flex items-center justify-center text-white text-[46px] custom-font-bold z-10">
                <img
                  src="/assets/images/landing/open-the-account.gif"
                  alt="open-the-account"
                  className="w-full h-full z-10"
                />
              </div>
            )}
            {activeTab.value === HOMESTARTED.tab3 && (
              <div className="h-[300px] w-[300px] bg-primary flex items-center text-center justify-center text-white text-[46px] custom-font-bold z-10">
                <img
                  src="/assets/images/landing/make-deposit.gif"
                  alt="make-deposit"
                  className="w-full h-full z-10"
                />
              </div>
            )}
            {activeTab.value === HOMESTARTED.tab4 && (
              <div className="h-[300px] w-[300px] bg-primary flex items-center text-center justify-center text-white text-[46px] custom-font-bold z-10">
                <img
                  src="/assets/images/landing/start-different-trading.gif"
                  alt="start-differnet"
                  className="w-full h-full z-10"
                />
              </div>
            )}
            {activeTab.value === HOMESTARTED.tab5 && (
              <div className="h-[300px] w-[300px] bg-primary flex items-center text-center justify-center text-white text-[46px] custom-font-bold z-10">
                <img
                  src="/assets/images/landing/easy-customer.gif"
                  alt="easy-customer"
                  className="w-full h-full z-10"
                />
              </div>
            )}
          </animated.div>
        </div>
      </div>
      <div className="flex xl:hidden w-full p-2">
        <div className="w-full h-full relative">
          <img
            src="/assets/images/landing/home-started-bg.svg"
            alt="choice"
            className="absolute inset-0 top-4 lg:-top-6 lg:w-1/2 -left-2 lg:left-1/4"
          />
          <Slider {...settings}>
            {choices.map((choice, index) => (
              <div
                key={index}
                className="relative h-[400px] !flex !flex-col justify-between items-center p-5"
              >
                <div></div>
                {choice.value === HOMESTARTED.tab1 && (
                  <div className="grid grid-cols-2 gap-1 items-center">
                    {choice?.values?.map((value, index) => (
                      <div
                        key={index}
                        className="relative bg-primary !aspect-square w-[130px] border-2 border-white flex flex-col items-center justify-center"
                      >
                        <div className="w-[50px] h-[50px] rounded-full bg-white mb-4 flex items-center justify-center">
                          <img
                            src={value.icon}
                            alt={value.name}
                            className="h-[30px] w-[30px]"
                          />
                        </div>
                        <h1 className="text-center text-green text-xl custom-font-bold">
                          {value.name}
                        </h1>
                      </div>
                    ))}
                  </div>
                )}
                {choice.value === HOMESTARTED.tab2 && (
                  <div className="h-[200px] w-[200px] bg-primary flex items-center justify-center text-white text-2xl custom-font-bold z-10">
                    <img
                      src="/assets/images/landing/open-the-account.gif"
                      alt="open-the-account"
                      className="w-full h-full z-10"
                    />
                  </div>
                )}
                {choice.value === HOMESTARTED.tab3 && (
                  <div className="h-[200px] w-[200px] bg-primary flex items-center justify-center text-white text-2xl custom-font-bold z-10">
                    <img
                      src="/assets/images/landing/make-deposit.gif"
                      alt="make-deposit"
                      className="w-full h-full z-10"
                    />
                  </div>
                )}
                {choice.value === HOMESTARTED.tab4 && (
                  <div className="h-[200px] text-center w-[200px] bg-primary flex items-center justify-center text-white text-2xl custom-font-bold z-10">
                    <img
                      src="/assets/images/landing/start-different-trading.gif"
                      alt="start-differnet"
                      className="w-full h-full z-10"
                    />
                  </div>
                )}
                {choice.value === HOMESTARTED.tab5 && (
                  <div className="h-[200px] text-center w-[200px] bg-primary flex items-center justify-center text-white text-2xl custom-font-bold z-10">
                    <img
                      src="/assets/images/landing/easy-customer.gif"
                      alt="easy-customer"
                      className="w-full h-full z-10"
                    />
                  </div>
                )}
                <div className="container mx-auto flex items-center bg-white">
                  <div
                    className={`button--hexagon w-[37px] h-[37px] flex items-center justify-center flex-shrink-0 mr-5 text-sm text-white custom-font-bold  bg-primary
                    `}
                  >
                    {index + 1}
                  </div>
                  <p className="w-full text-primary text-sm custom-font-bold p-2 rounded-xl text-center">
                    {choice.label}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;

const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      className="absolute right-[10px] top-1/2 translate-y-1/4 -mt-[50px] flex h-[56px] w-[56px] items-center justify-center rounded-full z-10"
      onClick={onClick}
    >
      <img
        src="/assets/icons/arrow-right.svg"
        alt="right-arrow"
        className="h-6 w-6"
      />
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      className="absolute left-[10px] top-1/2 translate-y-1/4 -mt-[50px] flex h-[56px] w-[56px] items-center justify-center rounded-full z-10"
      onClick={onClick}
    >
      <img
        src="/assets/icons/arrow-left.svg"
        alt="left-arrow"
        className="h-6 w-6"
      />
    </div>
  );
};
