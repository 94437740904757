import { FC, PropsWithChildren } from 'react';
import Hero from '../containers/Middleman/Hero';
import Tabs from '../containers/Middleman/Tabs';
import FAQs from './FAQs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ibFaqs, referFriendFaqs } from '../utils/faqs';
import { ROUTES } from '../utils/enums';

const MiddlemanLayout: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  return (
    <div>
      <Hero />
      <Tabs />
      {children}
      <div className="container mx-auto mt-10">
        <FAQs
          faqs={
            pathname === '/middleman/refer-a-friend'
              ? referFriendFaqs.slice(0, 3)
              : ibFaqs.slice(0, 3)
          }
          label={
            pathname === '/middleman/refer-a-friend'
              ? t('referAFriendFaq')
              : t('ibProxyFaq')
          }
          link={
            pathname === '/middleman/refer-a-friend'
              ? ROUTES.commonQuestionReferFriend
              : ROUTES.commonQuestionIbProxy
          }
        />
      </div>
    </div>
  );
};

export default MiddlemanLayout;
