import { useEffect, useRef, useState } from 'react';
import CommonAccount from '../../../containers/Home/CommonAccount';
import { ROUTES, TRADINGACCOUNTTAB } from '../../../utils/enums';
import Tabs from '../../../containers/Trading/Tabs';
// import ProtectionAccounts from '../../../containers/Trading/ProtectionAccounts';
import Common from '../../../containers/Trading/Common';
import FAQs from '../../../components/FAQs';
import FindOut from '../../../containers/Trading/FindOut';
import { useTranslation } from 'react-i18next';
import useQuery from '../../../hooks/useQuery';
import { commonFaqs, tradingAccountFaqs } from '../../../utils/faqs';

const TradingAccount = () => {
  const [activeTab, setActiveTab] = useState(TRADINGACCOUNTTAB.tab2);

  const demoRef = useRef<any>();

  const query = useQuery();

  const { t } = useTranslation();

  useEffect(() => {
    const tab = query.get('tab');

    if (tab === 'demo' && demoRef.current) {
      const topPosition = demoRef.current.offsetTop - 105;

      window.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      });
    }
  }, [query]);

  return (
    <div className="overflow-x-hidden">
      <CommonAccount tradingAccount />
      {/* <Tabs activeTab={activeTab} setActiveTab={setActiveTab} /> */}
      <div className="container mx-auto overflow-x-auto w-full px-2 xl:px-0">
        {/* {activeTab === TRADINGACCOUNTTAB.tab1 ? (
          <ProtectionAccounts />
        ) : (
          <Common />
        )} */}
        <Common />
      </div>
      <div ref={demoRef}>
        <FindOut />
      </div>
      <FAQs
        label={t('pricingFaqs')}
        faqs={tradingAccountFaqs.slice(0, 3)}
        link={ROUTES.commonQuestionTrading}
      />
    </div>
  );
};

export default TradingAccount;
