import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';

interface Props {
  className?: string;
  containerClass?: string;
  long?: boolean;
  veryLong?: boolean;
  onClick?: () => void;
  white?: boolean;
  noAnim?: boolean;
}

const Button: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  long,
  veryLong,
  onClick,
  noAnim,
  white,
  containerClass,
}) => {
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  const { opacity, transform, left } = useSpring({
    opacity: hovered ? 1 : 0,
    transform: clicked
      ? 'translate(-50%, 0%) rotate(-30deg)'
      : hovered
      ? 'translate(-50%, -50%) rotate(20deg)'
      : 'translate(-50%, -50%) rotate(0deg)',
    left: clicked ? '50%' : '-5px',
    config: { tension: 300, friction: 50 },
  });

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked(false);
      }, 2000);
    }
  }, [clicked]);

  return (
    <div className={`relative overflow-visible ${containerClass}`}>
      <button
        onClick={() => {
          !noAnim && setClicked(true);
          onClick && onClick();
        }}
        onMouseEnter={() => !noAnim && setHovered(true)}
        onMouseLeave={() => !noAnim && setHovered(false)}
        className={`${
          long
            ? 'button-long--hexagon'
            : veryLong
            ? 'button-very-long--hexagon'
            : 'button--hexagon'
        } ${
          noAnim
            ? ''
            : white
            ? 'hover:!bg-transparent border-4 border-white hover:text-white'
            : 'hover:!bg-transparent border-4 border-primary hover:text-primary'
        } ${
          white ? 'bg-white text-primary' : 'text-white bg-primary'
        } h-14 text-base custom-font-medium relative px-4 rounded-lg ${className}`}
      >
        {children}
        <animated.img
          className="absolute"
          style={{
            opacity,
            transform,
            left,
            bottom: '-20px',
            transformOrigin: 'center center',
          }}
          src={
            white
              ? '/assets/icons/btn-icon-white.svg'
              : '/assets/icons/btn-icon.svg'
          }
          alt="bt-icon"
        />
      </button>
    </div>
  );
};

export default Button;
