import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import Earth from '../../components/Earth';

const BookSystem = () => {
  const [showMore, setShowMore] = useState(false);

  const { t } = useTranslation();

  const animationProps = useSpring({
    opacity: showMore ? 1 : 0,
    maxHeight: showMore ? 1000 : 0,
    overflow: 'hidden',
    config: { duration: 300 },
  });

  return (
    <div
      className={`bg-primary ${showMore ? 'py-10 xl:py-20' : 'py-5 xl:py-0'}`}
    >
      <div className="container mx-auto">
        <div className="grid xl:grid-cols-2 h-fit gap-10 px-2 xl:px-0">
          <div className="flex flex-col justify-center order-2 xl:order-1">
            <h1 className="text-white text-[46px] xl:text-[64px] mb-5 text-center xl:text-left">
              {t('rBookSystem')}
            </h1>
            <p className="text-white text-lg mb-5">{t('itIsABusinessModel')}</p>
            {showMore && (
              <animated.div style={animationProps}>
                <p className="text-white text-lg mb-5">{t('inAn')}</p>
                <p className="text-white text-lg mb-5">
                  {t('onTheOtherHand')}
                  <br />
                  {t('beCarefulOf')}
                </p>
                <p className="text-white text-lg mb-5">{t('thereforeAbook')}</p>
              </animated.div>
            )}
            <div
              onClick={() => setShowMore(!showMore)}
              className="flex items-center text-lg text-white xl:self-start self-center cursor-pointer hover:underline transition-[300]"
            >
              {t('learnMore')}{' '}
              <img
                className={`ml-2 h-6 w-6 transform transition-transform duration-200 ${
                  showMore ? 'rotate-180' : ''
                }`}
                src="/assets/images/landing/arrow.svg"
                alt="arrow"
              />
            </div>
          </div>
          <div className="order-1 xl:order-2 relative flex items-center justify-center">
            <Earth isMore={showMore} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookSystem;
