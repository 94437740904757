import { useEffect, useMemo, useState } from 'react';
import Search from '../../../components/Search';
import Tabs from '../../../containers/Education/Tabs';
import { COMMONQUESTIONSTAB } from '../../../utils/enums';
import FAQs from '../../../components/FAQs';
import { useTranslation } from 'react-i18next';
import {
  aBookFaqs,
  commonFaqs,
  financialFaqs,
  ibFaqs,
  referFriendFaqs,
  safetyFaqs,
  tradingAccountFaqs,
  wheelFaqs,
} from '../../../utils/faqs';
import useQuery from '../../../hooks/useQuery';

const CommonQuestions = () => {
  const [activeTab, setActiveTab] = useState(COMMONQUESTIONSTAB.tab1);
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();

  const query = useQuery();

  useEffect(() => {
    const tab = query.get('tab') as COMMONQUESTIONSTAB;

    if (tab) {
      setActiveTab(tab || COMMONQUESTIONSTAB.tab1);
    }
  }, []);

  const tab = useMemo(() => {
    switch (activeTab) {
      case COMMONQUESTIONSTAB.tab1:
        return <FAQs faqs={aBookFaqs} label="" noButton />;
      case COMMONQUESTIONSTAB.tab2:
        return <FAQs faqs={safetyFaqs} label="" noButton />;
      case COMMONQUESTIONSTAB.tab3:
        return <FAQs faqs={ibFaqs} label="" noButton />;
      case COMMONQUESTIONSTAB.tab4:
        return <FAQs faqs={referFriendFaqs} label="" noButton />;
      case COMMONQUESTIONSTAB.tab5:
        return <FAQs faqs={wheelFaqs} label="" noButton />;
      case COMMONQUESTIONSTAB.tab6:
        return <FAQs faqs={tradingAccountFaqs} label="" noButton />;
      case COMMONQUESTIONSTAB.tab7:
        return <FAQs faqs={financialFaqs} label="" noButton />;
      case COMMONQUESTIONSTAB.tab8:
        return <FAQs faqs={commonFaqs} label="" noButton />;
    }
  }, [activeTab]);

  return (
    <div className="container mx-auto pt-5 xl:py-5 flex flex-col px-2 xl:px-0">
      <h1 className="text-[32px] text-center xl:text-left py-5 xl:py-0 xl:text-[64px] text-black dark:text-white order-2 xl:order-1">
        {t('commonQuestionsFaq')}
      </h1>
      {/* <div className="order-1 xl:order-2">
        <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div> */}
      <div className="order-3 overflow-x-auto">
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className="order-4">{tab}</div>
    </div>
  );
};

export default CommonQuestions;
