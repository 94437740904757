import { Link } from 'react-router-dom';
import Newsletter from './Newsletter';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../utils/enums';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="border-t xl:border-t-4 border-black bg-white dark:bg-light px-2 xl:px-0">
      <div className="container mx-auto">
        <Newsletter />
      </div>
      <div className="container mx-auto ">
        <div className="grid grid-cols-2 xl:grid-cols-4 pb-10 gap-10 xl:gap-0">
          <img
            className="hidden xl:block hover:scale-110 transition-[300]"
            src="/assets/images/logo/logo.svg"
            alt="logo"
          />
          <div className="flex flex-col items-center gap-5">
            <h6 className="text-2xl custom-font-bold text-black dark:text-white mb-3">
              {t('tradingAccount')}
            </h6>
            {/* <Link
              className="text-lg text-black dark:text-white"
              to="/trading/protection-accounts"
            >
              {t('protectionAccounts')}
            </Link> */}
            <Link
              className="text-lg text-black dark:text-white hover:underline transition-[300]"
              to={ROUTES.common}
            >
              {t('common')}
            </Link>
            <Link
              className="text-lg text-black dark:text-white hover:underline transition-[300]"
              to={ROUTES.demo}
            >
              {t('demo')}
            </Link>
            <div className="flex-col items-center gap-5 flex xl:hidden mt-5">
              <h6 className="text-2xl custom-font-bold text-black dark:text-white mb-3">
                {t('education')}
              </h6>
              <Link
                className="text-lg text-black dark:text-white hover:underline transition-[300]"
                to={ROUTES.commonQuestion}
              >
                {t('commonQuestions')}
              </Link>
              <Link
                className="text-lg text-black dark:text-white hover:underline transition-[300]"
                to={ROUTES.howDoYouDoIt}
              >
                {t('howDoYouDoIt')}
              </Link>
            </div>
          </div>
          <div className="flex flex-col items-center gap-5">
            <h6 className="text-2xl custom-font-bold text-black dark:text-white mb-3">
              {t('markets')}
            </h6>
            <Link
              className="text-lg text-black dark:text-white hover:underline transition-[300]"
              to={ROUTES.metals}
            >
              {t('metals')}
            </Link>
            <Link
              className="text-lg text-black dark:text-white hover:underline transition-[300]"
              to={ROUTES.forex}
            >
              {t('forex')}
            </Link>
            <Link
              className="text-lg text-black dark:text-white hover:underline transition-[300]"
              to={ROUTES.indices}
            >
              {t('indices')}
            </Link>
            <Link
              className="text-lg text-black dark:text-white hover:underline transition-[300]"
              to={ROUTES.stocks}
            >
              {t('stocks')}
            </Link>
            <Link
              className="text-lg text-black dark:text-white hover:underline transition-[300]"
              to={ROUTES.commodities}
            >
              {t('commodities')}
            </Link>
          </div>
          <div className="flex-col items-center gap-5 hidden xl:flex">
            <h6 className="text-2xl custom-font-bold text-black dark:text-white mb-3">
              {t('education')}
            </h6>
            <Link
              className="text-lg text-black dark:text-white hover:underline transition-[300]"
              to={ROUTES.commonQuestion}
            >
              {t('commonQuestions')}
            </Link>
            <Link
              className="text-lg text-black dark:text-white hover:underline transition-[300]"
              to={ROUTES.howDoYouDoIt}
            >
              {t('howDoYouDoIt')}
            </Link>
          </div>
        </div>
        <p className="xl:hidden text-sm text-black dark:text-white custom-font-medium text-center mb-10">
          © 2024 DAMU. {t('allRightsReserved')}
        </p>
        <div className="flex flex-col xl:flex-row items-center justify-between pb-10 border-b border-black dark:border-white">
          <div className="flex items-center gap-5 mb-10 xl:mb-0">
            <p className="hidden xl:block text-sm text-black dark:text-white custom-font-medium">
              © 2024 DAMU. {t('allRightsReserved')}
            </p>
            <Link
              to="/privacy-policy"
              className="text-sm text-black dark:text-white custom-font-medium hover:scale-105 transition-[300]"
            >
              <u>{t('privacyPolicy')}</u>
            </Link>
            <Link
              to="/terms-of-service"
              className="text-sm text-black dark:text-white custom-font-medium hover:scale-105 transition-[300]"
            >
              <u>{t('termsOfService')}</u>
            </Link>
            <Link
              to="/cookies-settings"
              className="text-sm text-black dark:text-white custom-font-medium hover:scale-105 transition-[300]"
            >
              <u>{t('cookiesSettings')}</u>
            </Link>
          </div>
          <div className="flex items-center gap-5">
            <Link
              to="https://www.facebook.com/profile.php?id=61560961500655&mibextid=LQQJ4d"
              target="_blank"
              className="hover:scale-125 transition-[300]"
            >
              <img
                src="/assets/icons/facebook.svg"
                alt="facebook"
                width={24}
                height={24}
              />
            </Link>
            <Link
              to="https://www.instagram.com/damu.arabia?igsh=dmpwYzQ4M3BwMWIz"
              target="_blank"
              className="hover:scale-125 transition-[300]"
            >
              <img
                src="/assets/icons/instagram.svg"
                alt="instagram"
                width={24}
                height={24}
              />
            </Link>
            <Link
              to="https://youtube.com/@damubroker?si=QhCcxh-iupC-v0oA"
              target="_blank"
              className="hover:scale-125 transition-[300]"
            >
              <img
                src="/assets/icons/youtube.svg"
                alt="youtube"
                width={24}
                height={24}
              />
            </Link>
            <Link
              to="https://t.me/damubroker"
              target="_blank"
              className="hover:scale-125 transition-[300]"
            >
              <img
                src="/assets/icons/telegram.svg"
                alt="telegram"
                width={24}
                height={24}
              />
            </Link>
          </div>
        </div>
        <div className="py-10 text-lg text-black dark:text-white">
          {t('damuLtdBroker')}
          <br />
          {t('registerAddress')}
          <br />
          {t('officeAddress')}
          <br />
          {t('damuLtdDoesNot')}
          <br />
          {t('riskWarning')}
        </div>
      </div>
    </div>
  );
};

export default Footer;
