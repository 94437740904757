import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  arabicArticles,
  articles,
  spanishArticles,
} from '../../utils/constants';
import { useMemo } from 'react';

const ArticlesList = () => {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const items = useMemo(() => {
    const artis =
      currentLanguage === 'en'
        ? articles
        : currentLanguage === 'es'
        ? spanishArticles
        : currentLanguage === 'ar'
        ? arabicArticles
        : articles;

    return artis;
  }, [currentLanguage]);

  return (
    <div className="grid lg:grid-cols-3 gap-5 mt-10 w-full lg:px-5">
      {items.map((item, index) => {
        return (
          <Link
            to={`/education/articles/${item.id}`}
            key={index}
            className="border-[3px] border-primary p-3 cursor-pointer"
            style={{
              background:
                'linear-gradient(to top, rgba(102, 0, 255, 1), rgba(131, 48, 255, 0.3))',
            }}
          >
            <div className="flex items-center justify-between bg-primary px-2 rounded-md h-11 mb-2">
              <span className="custom-font-medium text-white">{item.name}</span>
              <span className="custom-font-medium text-white">2024</span>
            </div>
            <div className="aspect-square bg-gray p-4 rounded-md flex flex-col justify-between relative">
              <span />
              <div className="z-[1]">
                {/* <h6 className="text-primary text-2xl custom-font-bold mb-2">
                  {t('featuredCourses')}
                </h6> */}
                <p className="text-lg text-right text-white custom-font-bold">
                  {t('readMore')}
                </p>
              </div>
              <img
                src={
                  index === 0
                    ? '/assets/images/education/currency-float.png'
                    : index === 1
                    ? '/assets/images/education/spread.png'
                    : index === 2
                    ? '/assets/images/education/lot.png'
                    : index === 3
                    ? '/assets/images/education/book-system.png'
                    : index === 4
                    ? '/assets/images/education/leverage.png'
                    : index === 5
                    ? '/assets/images/education/forex-market.png'
                    : index === 6
                    ? '/assets/images/education/federal-reserve.png'
                    : index === 7
                    ? '/assets/images/education/strategy.png'
                    : '/assets/images/education/limit-orders.png'
                }
                alt={item.name}
                className="absolute object-cover h-full object-left inset-0 z-0"
              />
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default ArticlesList;
