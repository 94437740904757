import { FC, useEffect, useRef, useState } from 'react';
import Dropdown from './Dropdown';
import useOutsideAlerter from '../hooks/useOnClickOutside';
import { languages } from '../utils/constants';
import { useTranslation } from 'react-i18next';
import Button from './Button';

interface Props {
  button?: boolean;
}

const SelectLanguage: FC<Props> = ({ button }) => {
  const { i18n } = useTranslation();

  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);
  useOutsideAlerter(dropdownRef, () => setShowDropdown(false));

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <div ref={dropdownRef} className="relative">
      {button ? (
        <Button
          onClick={() => setShowDropdown((prev) => !prev)}
          containerClass="w-full"
          white
          className="!text-black flex items-center justify-center w-full"
          long
        >
          {i18n.language === 'en'
            ? 'English'
            : i18n.language === 'es'
            ? 'Español'
            : 'عربي'}{' '}
          <img src="/assets/icons/lang.svg" alt="moon" className="mx-3" />
        </Button>
      ) : (
        <img
          onClick={() => setShowDropdown((prev) => !prev)}
          src="/assets/icons/globe.svg"
          alt="globe"
          className="cursor-pointer hidden xl:block hover:scale-125 transition-[1000]"
        />
      )}
      <Dropdown isOpen={showDropdown}>
        <div
          className={`absolute left-1/2 -translate-x-1/2 top-[120%] w-[90px] z-[99999999] border-2 border-white px-3 py-2 bg-primary rounded-sm`}
        >
          {languages.map((lng, index) => (
            <div
              className="py-3 border-b border-white last:border-b-0 text-white cursor-pointer text-center hover:text-black hover:scale-110 transition-all duration-300"
              key={index}
              onClick={() => i18n.changeLanguage(lng.code)}
            >
              {lng.lang}
            </div>
          ))}
        </div>
      </Dropdown>
    </div>
  );
};

export default SelectLanguage;
