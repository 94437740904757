import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const Search: FC<PropsWithChildren<Props>> = ({
  searchTerm,
  setSearchTerm,
}) => {
  const { t } = useTranslation();

  return (
    <label
      htmlFor="search"
      className="h-[54px] flex items-center px-3 rounded-lg bg-gray"
    >
      <img src="/assets/icons/search.svg" alt="search" height={24} width={24} />
      <input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        id="search"
        placeholder={t('search')}
        className="flex-1 h-full border-none bg-transparent outline-none pl-3 rtl:pr-3 text-white"
      />
    </label>
  );
};

export default Search;
