import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="relative xl:mb-10">
      <img
        src="/assets/images/trading/hero.svg"
        alt="hero"
        className="w-full object-cover h-[420px] xl:h-auto"
      />
      <h1 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-white custom-font-bold text-[60px] z-[1]">
        {t('financialMarkets')}
      </h1>
    </div>
  );
};

export default Hero;
