import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { useEffect, useState } from 'react';
import PDFViewer from '../../../components/PDFViewer';

const ReferAFriend = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const initialSteps = [
    {
      name: t('registeredInvitation'),
      icon: '/assets/images/middleman/invitation.svg',
      done: false,
    },
    {
      line: true,
    },
    {
      name: t('kycVerification'),
      icon: '/assets/images/middleman/verification.svg',
      done: false,
    },
    {
      line: true,
    },
    {
      name: t('theFirstDeposit'),
      icon: '/assets/images/middleman/first-deposit.svg',
      done: false,
    },
    {
      line: true,
    },
    {
      name: t('repeatDeposit'),
      icon: '/assets/images/middleman/second-deposit.svg',
      done: false,
    },
    {
      line: true,
    },
    {
      name: t('consumedLot'),
      icon: '/assets/images/middleman/lot.svg',
      done: false,
    },
  ];

  const [steps, setSteps] = useState(initialSteps);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const newSteps = [...steps];
      const currentIndex = stepIndex;

      // Find the next non-line step to mark as done
      for (let i = currentIndex; i < newSteps.length; i++) {
        if (!newSteps[i].line && !newSteps[i].done) {
          newSteps[i].done = true;
          setStepIndex(i + 1);
          setSteps(newSteps);
          break;
        }
      }

      // If all steps are done, reset steps
      if (stepIndex >= newSteps.length - 1) {
        setTimeout(() => {
          setSteps(initialSteps);
          setStepIndex(0);
        }, 1000);
      }
    }, 1000);

    // Clean up the timeout on unmount
    return () => clearTimeout(timer);
  }, [steps, stepIndex]);

  const url = '../../assets/docs/Refer-a-Friend.pdf';

  return (
    <>
      <p className="container mx-auto text-primary custom-font-bold text-[24px] mt-10 xl:mt-0 mb-10 xl:text-[40px] text-center">
        {t('startMakingYourProfits')}
      </p>
      <div className="bg-white m-2 xl:m-0">
        <div className="pb-5 xl:pb-0 xl:py-10 border-4 xl:border-0 xl:border-t-4 xl:border-b-4 border-primary mb-10">
          <div className="container mx-auto p-4 xl:p-0">
            <div className="bg-primary flex items-center justify-center mb-10">
              <img
                src="/assets/images/middleman/refer-friend.png"
                alt="refer-friend"
                className="w-full"
              />
            </div>
            <p className="text-black text-xl xl:text-2xl mb-5 text-center">
              {t('theReferrer')}
            </p>
            <div className="grid grid-cols-2 gap-5 xl:gap-0 xl:grid-cols-9 items-center">
              {steps.map((step, index) => {
                if (step.line) {
                  return (
                    <div className="border-t-4 border-dashed border-primary hidden xl:block"></div>
                  );
                }
                return (
                  <div
                    className={`w-full p-5 border-2 border-primary rounded-sm flex flex-col items-center justify-center transition-all duration-1000 ${
                      index === steps.length - 1
                        ? 'col-span-2 xl:col-span-1'
                        : ''
                    }  ${step.done ? 'bg-primary' : 'bg-white'}`}
                    key={index}
                  >
                    <img src={step.icon} alt={step.name} width={'70%'} />
                    <p
                      className={`text-center ${
                        step.done ? 'text-white' : 'text-primary'
                      } custom-font-bold text-2xl mt-3`}
                    >
                      {step.name}
                    </p>
                  </div>
                );
              })}
            </div>
            <Button
              onClick={() =>
                window.open('https://my.damu.co/register', '_blank')
              }
              veryLong
              className="!mx-0 !my-10 w-full"
            >
              {t('openNewAccount')}
            </Button>
            <div className="flex items-center justify-center mb-5">
              <img
                src="/assets/images/landing/file.svg"
                alt="file"
                className="h-6 w-6 mr-3"
              />
              <p
                onClick={() => setShowModal(true)}
                className="text-black font-custom-medium"
              >
                {t('termsAndConditionFile')}
              </p>
            </div>
          </div>
        </div>
      </div>
      {showModal && <PDFViewer url={url} onClose={() => setShowModal(false)} />}
    </>
  );
};

export default ReferAFriend;
