import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';

const Table = () => {
  const { t } = useTranslation();

  const data = [
    {
      field: t('tradedLotSize'),
      value: ['1-49', '50-999', '+1000'],
    },
    {
      field: t('agencyType'),
      value: [t('multiple'), t('multiple'), t('exclusive')],
    },
    {
      field: t('commission'),
      value: ['3$', '5$', '7$'],
    },
  ];

  return (
    <div className="overflow-x-auto lg:px-5">
      <div className="my-20 w-[700px] lg:w-full overflow-x-hidden">
        <div className="grid grid-cols-4 h-[65px] items-center border-b border-black dark:border-white gap-2">
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold">
            {t('properties')}
          </p>
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold text-center">
            Basic
          </p>
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold text-center">
            Plus
          </p>
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold text-center">
            Expert
          </p>
        </div>
        {data.map((field, index) => {
          return (
            <div
              key={index}
              className="grid grid-cols-4 h-[65px] items-center border-b border-black dark:border-white gap-2"
            >
              <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold flex justify-between items-center">
                {field.field}
                <img
                  src="/assets/icons/info.svg"
                  alt="info"
                  width={20}
                  height={20}
                />
              </p>
              {field.value.map((value, index) => (
                <p
                  key={index}
                  className="text-sm lg:text-lg text-black dark:text-white text-center"
                >
                  {value}
                </p>
              ))}
            </div>
          );
        })}
        <div className="grid grid-cols-4 h-[65px] items-center gap-2">
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold">
            {t('accounts')}
          </p>
          <div className="col-span-3">
            <Button
              onClick={() =>
                window.open('https://my.damu.co/partner/register', '_blank')
              }
              className="!h-11 text-sm !lg:text-base w-full"
              veryLong
            >
              {t('openNewAccount')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
