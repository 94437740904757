import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useTexture } from '@react-three/drei';
import * as THREE from 'three';

interface Props {
  isMore: any;
}

const Earth: React.FC<Props> = ({ isMore }) => {
  const meshRef = useRef<THREE.Mesh>(null);
  const texture = useTexture('/assets/images/offers/book-system.svg');

  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.001; // Auto-rotate
    }
  });

  return (
    <mesh ref={meshRef} rotation={[0, 0, 0]}>
      <sphereGeometry
        args={[windowWidth < 1025 ? 2.5 : isMore ? 3.2 : 2.6, 132, 132]}
      />
      <meshBasicMaterial map={texture} side={THREE.DoubleSide} transparent />
    </mesh>
  );
};

const RotateSvg: React.FC<Props> = ({ isMore }) => {
  return (
    <Canvas
      className="flex items-center justify-center"
      style={{ background: 'transparent', height: '500px' }}
    >
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Earth isMore={isMore} />
      <OrbitControls enablePan={false} enableZoom={false} />
    </Canvas>
  );
};

export default RotateSvg;
