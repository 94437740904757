export enum ROUTES {
  home = '/',
  offers = '/offers',
  about = '/about',
  referFriend = '/middleman/refer-a-friend',
  ibProxy = '/middleman/ib-proxy',
  tradingAccount = '/trading/trading-account',
  common = '/trading/trading-account?tab=common',
  protectionAccounts = '/trading/trading-account?tab=protection-accounts',
  demo = '/trading/trading-account?tab=demo',
  financialMarkets = '/trading/financial-markets',
  commodities = '/trading/financial-markets?tab=commodities',
  stocks = '/trading/financial-markets?tab=stocks',
  indices = '/trading/financial-markets?tab=indices',
  forex = '/trading/financial-markets?tab=forex',
  metals = '/trading/financial-markets?tab=metals',
  privacyPolicy = '/privacy-policy',
  termsOfService = '/terms-of-service',
  commonQuestion = '/education/common-questions',
  commonQuestionCommon = '/education/common-questions?tab=Common account',
  commonQuestionTrading = '/education/common-questions?tab=Trading accounts',
  commonQuestionReferFriend = '/education/common-questions?tab=Refer a friend',
  commonQuestionIbProxy = '/education/common-questions?tab=IB Proxy',
  commonQuestionFinancial = '/education/common-questions?tab=Financial markets',
  commonQuestionIbWheel = '/education/common-questions?tab=Wheel of fortune',
  howDoYouDoIt = '/education/how-do-you-do-it',
  articles = '/education/articles',
  singleArticle = '/education/articles/:slug',
}

export enum HOMECHOICES {
  tab1 = 'tab1',
  tab2 = 'tab2',
  tab3 = 'tab3',
  tab4 = 'tab4',
  tab5 = 'tab5',
  tab6 = 'tab6',
  tab7 = 'tab7',
}

export enum HOMESTARTED {
  tab1 = 'tab1',
  tab2 = 'tab2',
  tab3 = 'tab3',
  tab4 = 'tab4',
  tab5 = 'tab5',
}

export enum ABOUTTAB {
  tab1 = 'Customer',
  tab2 = 'Services',
}

export enum TRADINGACCOUNTTAB {
  tab1 = 'Protection accounts',
  tab2 = 'Common',
}

export enum FINANCIALMARKETSTAB {
  tab1 = 'Commodities',
  tab2 = 'Stocks',
  tab3 = 'Indices',
  tab4 = 'Forex',
  tab5 = 'Metals',
}

export enum COMMONQUESTIONSTAB {
  tab1 = 'A-book system',
  tab2 = 'Money Security',
  tab3 = 'IB Proxy',
  tab4 = 'Refer a friend',
  tab5 = 'Wheel of fortune',
  tab6 = 'Trading accounts',
  tab7 = 'Financial markets',
  tab8 = 'Common account',
}
