import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';

interface Props {
  isOpen: boolean;
}

const Dropdown: FC<PropsWithChildren<Props>> = ({ children, isOpen }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  const animationStyles = useSpring({
    height: isOpen ? contentHeight : 0,
    opacity: isOpen ? 1 : 0,
    overflow: 'hidden',
  });

  return (
    <animated.div style={animationStyles}>
      {isOpen && <div ref={contentRef}>{children}</div>}
    </animated.div>
  );
};

export default Dropdown;
