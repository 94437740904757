import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center flex-col">
      <h1>{t('pageNotFound')}</h1>
      <p>{t('pageDoesntExist')}</p>
    </div>
  );
};

export default NotFound;
