import { useTranslation } from 'react-i18next';
import Leaderboard from '../../containers/Home/Leaderboard';
import Hero from '../../containers/Offers/Hero';
import LotsDone from '../../containers/Offers/LotsDone';
import OffersReceived from '../../containers/Offers/OffersReceived';
import FAQs from '../../components/FAQs';
import { wheelFaqs } from '../../utils/faqs';
import { ROUTES } from '../../utils/enums';

const Offers = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className="text-center mt-10 custom-font-bold text-5xl dark:text-white text-black">
        {t('wheelOfFortune')}
      </h1>
      <Hero />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* <Leaderboard /> */}
      {/* <OffersReceived /> */}
      <FAQs
        faqs={wheelFaqs.slice(0, 3)}
        label={t('offersFaq')}
        link={ROUTES.commonQuestionIbWheel}
      />
    </div>
  );
};

export default Offers;
