import React, { FC, PropsWithChildren, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';

interface Props {
  label: string;
}

const MobileNavLink: FC<PropsWithChildren<Props>> = ({ label, children }) => {
  const [open, setIsOpen] = useState(false);

  const animationProps = useSpring({
    opacity: open ? 1 : 0,
    maxHeight: open ? 500 : 0,
    overflow: 'hidden',
    config: { duration: 300 },
  });

  return (
    <div>
      <div
        className="text-white flex items-center text-lg cursor-pointer mt-10"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {label}
        <img
          src={'/assets/icons/chevron-down.svg'}
          className={`h-4 w-4 mx-3 transform transition-transform ${
            open ? 'rotate-180' : ''
          }`}
          alt="down"
        />
      </div>
      <animated.div style={animationProps}>{open && children}</animated.div>
    </div>
  );
};

export default MobileNavLink;
