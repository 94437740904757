import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="relative z-10 bg-primary lg:border-b-4 border-primary mb-5 lg:mb-10">
      <video
        className="h-[525px] lg:h-[607px] !w-full object-cover lg:object-fill bg-primary mix-blend-plus-lighter"
        autoPlay={true}
        controlsList="nofullscreen"
        loop
        playsInline
      >
        <source src="/assets/images/offers/stars.mp4" type="video/mp4" />
      </video>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="container mx-auto">
          <h1 className="text-[32px] lg:text-[64px] text-white custom-font-bold text-center mb-10">
            {t('aboutDamu')}
          </h1>
          <p className="lg:text-lg text-white text-center w-full lg:w-3/4 mx-auto px-2 lg:px-0">
            {t('DamiIsAFinancial')}{' '}
            <u>
              <b className="custom-font-bold">{t(`aBook"`)}</b>
            </u>{' '}
            {t('systemDirectly')}{' '}
            <u>
              <b className="custom-font-bold">"{t('protection')}"</b>
            </u>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
