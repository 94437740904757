import { useTranslation } from 'react-i18next';
import Button from './Button';
import { Link } from 'react-router-dom';
import { ROUTES } from '../utils/enums';

const Newsletter = () => {
  const { t } = useTranslation();

  return (
    <div className="py-5 xl:py-10 flex flex-col xl:flex-row items-center justify-between">
      <img
        className="block xl:hidden my-5"
        src="/assets/images/logo/logo.svg"
        alt="logo"
      />
      <div className="mb-4 xl:mb-0">
        <h1 className="text-2xl text-black dark:text-white custom-font-bold">
          {t('joinOurNewsletter')}
        </h1>
      </div>
      <div>
        <div className="flex items-center mb-4">
          <div className="button-long--hexagon bg-primary px-1 rounded-lg rtl:ml-2 mr-2 py-1">
            <input
              className="px-4 text-sm text-black outline-none w-[300px] h-[52px] bg-white dark:bg-light"
              placeholder={t('enterYourEmail')}
            />
          </div>
          <Button className="text-xs xl:text-base ">{t('joinNow')}</Button>
        </div>
        <p className="pl-3 text-xs text-black dark:text-white">
          {t('bySubscribing')}{' '}
          <u>
            <Link to={ROUTES.privacyPolicy}>{t('privacyPolicy')}</Link>
          </u>
        </p>
      </div>
    </div>
  );
};

export default Newsletter;
