import { FC, PropsWithChildren } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div>
      <Navbar />
      <div className="pt-[70px] xl:pt-[106px] bg-white dark:bg-light overflow-x-hidden">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
