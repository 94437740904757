import { useTranslation } from 'react-i18next';
import FAQs from '../../components/FAQs';
import BookSystem from '../../containers/About/BookSystem';
import Hero from '../../containers/About/Hero';
import RateYourExperience from '../../containers/About/RateYourExperience';

const About = () => {
  const { t } = useTranslation();

  const faqs = [
    {
      question: t('termsAndConditions'),
      answer: [
        t('commissionWillBePaid'),
        t('bonusIsPaid'),
        t('agentMustNotEngage'),
        t('minimumAmountOfCommission'),
        t('damuReserves'),
        t('agentIsStrictly'),
      ],
    },
    {
      question: t('termsAndConditions'),
      answer: [
        t('commissionWillBePaid'),
        t('bonusIsPaid'),
        t('agentMustNotEngage'),
        t('minimumAmountOfCommission'),
        t('damuReserves'),
        t('agentIsStrictly'),
      ],
    },
    {
      question: t('termsAndConditions'),
      answer: [
        t('commissionWillBePaid'),
        t('bonusIsPaid'),
        t('agentMustNotEngage'),
        t('minimumAmountOfCommission'),
        t('damuReserves'),
        t('agentIsStrictly'),
      ],
    },
  ];

  return (
    <div>
      <Hero />
      <BookSystem />
      <RateYourExperience />
    </div>
  );
};

export default About;
