import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  links:
    | {
        name: string;
        href: string;
        links?:
          | {
              name: string;
              href: string;
            }[];
      }[]
    | undefined;
}

const MenuDropdown: FC<Props> = ({ links }) => {
  const gridCols = useMemo(() => `grid-cols-${links?.length}`, [links]);

  const subGridCols = (link: {
    name: string;
    href: string;
    links?:
      | {
          name: string;
          href: string;
        }[];
  }) => {
    if (link.links) {
      return `grid-cols-${link.links?.length}`;
    } else {
      return '';
    }
  };

  return (
    <div
      className={`absolute min-h-[59px] left-0 top-[100%] w-full overflow-hidden bg-black dark:bg-white border-t-2 border-b-2 border-primary flex z-[99999999] py-2`}
    >
      <div className={`container mx-auto grid ${gridCols}`}>
        {links &&
          links.map((link, index) => (
            <Link
              key={index}
              to={link.href}
              className={`border-r-2 rtl:border-l-2 rtl:border-r-0 rtl:last:border-l-0 last:border-r-0 border-primary group ${
                link.links && link.links.length > 0
                  ? ''
                  : 'flex items-center justify-center h-full'
              }`}
            >
              <div
                className={`${
                  link.links && link.links.length > 0 ? '' : 'h-full'
                } flex items-center cursor-pointer justify-center text-white dark:text-primary  text-lg custom-font-bold group-hover:underline`}
              >
                {link.name}
              </div>
              {link.links && link.links.length && link.links.length > 0 ? (
                <div className={`grid ${subGridCols(link)} mt-4`}>
                  {link.links.map((link, index) => (
                    <Link
                      className="text-center text-lg text-white dark:text-primary custom-font-regular hover:underline"
                      to={link.href}
                      key={index}
                    >
                      {link.name}
                    </Link>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </Link>
          ))}
      </div>
    </div>
  );
};

export default MenuDropdown;
