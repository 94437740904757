import HowDoYou from '../../../containers/Education/HowDoYou';
import { useTranslation } from 'react-i18next';

const HowDoYouDoIt = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto pt-5 xl:py-5 flex flex-col px-2 xl:px-0">
      <h1 className="text-[32px] text-center xl:text-left py-5 xl:py-0 xl:text-[64px] text-black dark:text-white order-2 xl:order-1">
        {t('howDoYouDoIt')}
      </h1>
      <div className="order-4">
        <HowDoYou />
      </div>
    </div>
  );
};

export default HowDoYouDoIt;
