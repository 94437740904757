import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './utils/enums';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Layout from './components/Layout';
import Offers from './pages/Offers';
import About from './pages/About';
import IpProxy from './pages/Middleman/IpProxy';
import MiddlemanLayout from './components/MiddlemanLayout';
import ReferAFriend from './pages/Middleman/ReferAFriend';
import TradingAccount from './pages/Trading/TradingAccount';
import FinancialMarkets from './pages/Trading/FinancialMarkets';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CommonQuestions from './pages/Education/CommonQuestions';
import HowDoYouDoIt from './pages/Education/HowDoYouDoIt';
import Articles from './pages/Education/Articles';
import SingleArticle from './pages/Education/Articles/SingleArticle';
import TermsOfService from './pages/TermsOfService';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://embed.tawk.to/667c07d8eaf3bd8d4d148afa/1i1a7t9o6';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    const s0 = document.getElementsByTagName('script')[0];
    if (s0 && s0.parentNode) {
      s0.parentNode.insertBefore(script, s0);
    }

    return () => {
      // Clean up the script if the component unmounts
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <Layout>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={ROUTES.termsOfService} element={<TermsOfService />} />
        <Route path={ROUTES.home} element={<Home />} />
        <Route path={ROUTES.commonQuestion} element={<CommonQuestions />} />
        <Route path={ROUTES.howDoYouDoIt} element={<HowDoYouDoIt />} />
        <Route path={ROUTES.articles} element={<Articles />} />
        <Route path={ROUTES.singleArticle} element={<SingleArticle />} />
        <Route path={ROUTES.tradingAccount} element={<TradingAccount />} />
        <Route path={ROUTES.financialMarkets} element={<FinancialMarkets />} />
        <Route path={ROUTES.offers} element={<Offers />} />
        <Route path={ROUTES.about} element={<About />} />
        <Route
          path={ROUTES.ibProxy}
          element={
            <MiddlemanLayout>
              <IpProxy />
            </MiddlemanLayout>
          }
        />
        <Route
          path={ROUTES.referFriend}
          element={
            <MiddlemanLayout>
              <ReferAFriend />
            </MiddlemanLayout>
          }
        />
      </Routes>
    </Layout>
  );
}

export default App;
