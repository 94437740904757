import { FC, useEffect, useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { ROUTES } from '../utils/enums';
import MobileNavLink from './MobileNavLink';
import { Link, useLocation } from 'react-router-dom';
import Button from './Button';
import { useTheme } from '../context/ThemeContext';
import useOutsideAlerter from '../hooks/useOnClickOutside';
import { useTranslation } from 'react-i18next';
import SelectLanguage from './SelectLanguage';

interface Props {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  routes: (
    | {
        name: string;
        href: ROUTES;
        mutliple: boolean;
        hrefs?: undefined;
      }
    | {
        name: string;
        mutliple: boolean;
        hrefs: {
          name: string;
          href: ROUTES;
          links: {
            name: string;
            href: ROUTES;
          }[];
        }[];
        href?: undefined;
      }
  )[];
}

const MobileNavbar: FC<Props> = ({ isOpen, setIsOpen }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const { toggleTheme, theme } = useTheme();

  const dropdownRef = useRef(null);
  useOutsideAlerter(dropdownRef, () => setIsOpen(false));

  const { pathname } = useLocation();

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isOpen]);

  const animationStyles = useSpring({
    height: isOpen ? contentHeight : 0,
    opacity: isOpen ? 1 : 0,
    overflow: 'hidden',
    zIndex: '100',
  });

  const { t } = useTranslation();

  // Touch handling for swipe gesture
  let startX = 0;

  const handleTouchStart = (e: React.TouchEvent) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (startX - e.touches[0].clientX > 50) {
      // Swipe left
      setIsOpen(false);
    }
  };

  return (
    <animated.div style={animationStyles}>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="fixed left-0 top-0 w-70% h-screen overflow-y-auto overflow-x-hidden bg-primary z-50 p-3"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div className="flex flex-col items-center">
            <img
              src="/assets/icons/damu.svg"
              alt="damu"
              className="py-5 pb-10 border-b border-white"
            />
            <div className="w-full pt-10 flex flex-col">
              <Link
                to={ROUTES.home}
                className={`text-white text-lg relative ${
                  pathname === ROUTES.home
                    ? '!text-secondary custom-font-bold'
                    : ''
                }`}
              >
                {t('home')}
              </Link>
              <MobileNavLink label={t('trading')}>
                <Link
                  className={`text-sm custom-font-bold text-white mt-10 block ${
                    pathname === ROUTES.tradingAccount
                      ? '!text-secondary custom-font-bold'
                      : ''
                  }`}
                  to={ROUTES.tradingAccount}
                >
                  {t('tradingAccount')}
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  className="text-white mt-5 block"
                  to={ROUTES.common}
                >
                  {t('common')}
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  className="text-white mt-5 block"
                  to={ROUTES.demo}
                >
                  {t('demo')}
                </Link>
                <Link
                  className={`text-sm custom-font-bold text-white mt-10 block ${
                    pathname === ROUTES.financialMarkets
                      ? '!text-secondary custom-font-bold'
                      : ''
                  }`}
                  to={ROUTES.financialMarkets}
                >
                  {t('markets')}
                </Link>
                <Link
                  className="text-white mt-5 block"
                  to={ROUTES.commodities}
                  onClick={() => setIsOpen(false)}
                >
                  {t('commodities')}
                </Link>
                <Link
                  className="text-white mt-5 block"
                  to={ROUTES.stocks}
                  onClick={() => setIsOpen(false)}
                >
                  {t('stocks')}
                </Link>
                <Link
                  className="text-white mt-5 block"
                  to={ROUTES.indices}
                  onClick={() => setIsOpen(false)}
                >
                  {t('indices')}
                </Link>
                <Link
                  className="text-white mt-5 block"
                  to={ROUTES.forex}
                  onClick={() => setIsOpen(false)}
                >
                  {t('forex')}
                </Link>
                <Link
                  className="text-white mt-5 block"
                  to={ROUTES.metals}
                  onClick={() => setIsOpen(false)}
                >
                  {t('metals')}
                </Link>
              </MobileNavLink>
              <MobileNavLink label={t('middleman')}>
                <Link
                  className={`text-white mt-5 block ${
                    pathname === ROUTES.referFriend
                      ? '!text-secondary custom-font-bold'
                      : ''
                  }`}
                  to={ROUTES.referFriend}
                >
                  {t('referAFriend')}
                </Link>
                <Link
                  className={`text-white mt-5 block ${
                    pathname === ROUTES.ibProxy
                      ? '!text-secondary custom-font-bold'
                      : ''
                  }`}
                  to={ROUTES.ibProxy}
                >
                  {t('IpProxy')}
                </Link>
              </MobileNavLink>
              <MobileNavLink label={t('education')}>
                <Link
                  className={`text-white mt-5 block ${
                    pathname === ROUTES.articles
                      ? '!text-secondary custom-font-bold'
                      : ''
                  }`}
                  to={ROUTES.articles}
                >
                  {t('articles')}
                </Link>
                <Link
                  className={`text-white mt-5 block ${
                    pathname === ROUTES.howDoYouDoIt
                      ? '!text-secondary custom-font-bold'
                      : ''
                  }`}
                  to={ROUTES.howDoYouDoIt}
                >
                  {t('howDoYouDoIt')}
                </Link>
                <Link
                  className={`text-white mt-5 block ${
                    pathname === ROUTES.commonQuestion
                      ? '!text-secondary custom-font-bold'
                      : ''
                  }`}
                  to={ROUTES.commonQuestion}
                >
                  {t('commonQuestions')}
                </Link>
              </MobileNavLink>
              <Link
                to={ROUTES.offers}
                className={`text-white text-lg relative mt-10 ${
                  pathname === ROUTES.offers
                    ? '!text-secondary custom-font-bold'
                    : ''
                }`}
              >
                {t('offers')}
              </Link>
              <Link
                to={ROUTES.about}
                className={`text-white text-lg relative mt-5 mb-10 ${
                  pathname === ROUTES.about
                    ? '!text-secondary custom-font-bold'
                    : ''
                }`}
              >
                {t('aboutUs')}
              </Link>
              <div className="grid grid-cols-2 gap-2">
                <SelectLanguage button />
                <Button
                  white
                  containerClass="w-full"
                  onClick={() =>
                    toggleTheme(theme === 'dark' ? 'light' : 'dark')
                  }
                  className="text-primary flex items-center justify-center w-full"
                  long
                >
                  {theme === 'dark' ? t('light') : t('dark')}
                  <img
                    src={
                      theme === 'dark'
                        ? '/assets/icons/sun-primary.svg'
                        : '/assets/icons/moon-primary.svg'
                    }
                    alt="moon"
                    className="mx-3"
                  />
                </Button>
              </div>
              <div className="pt-[200px] flex flex-col items-center justify-center w-full pb-[80px]">
                <span
                  onClick={() => window.open('https://my.damu.co', '_blank')}
                  className="custom-font-medium text-sm text-white cursor-pointer text-center mb-4"
                >
                  {t('signIn')}
                </span>
                <Button
                  onClick={() =>
                    window.open('https://my.damu.co/register', '_blank')
                  }
                  white
                  className="!w-[100%] mb-4"
                  containerClass="w-full"
                >
                  {t('realAccount')}
                </Button>
                <Button
                  onClick={() =>
                    window.open('https://my.damu.co/register', '_blank')
                  }
                  white
                  className="!w-[100%]"
                  containerClass="!w-full"
                >
                  {t('demoAccount')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </animated.div>
  );
};

export default MobileNavbar;
