import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../utils/enums';
import { useTheme } from '../../context/ThemeContext';
import { useTranslation } from 'react-i18next';

const Tabs = () => {
  const { pathname } = useLocation();

  const { theme } = useTheme();

  const { t } = useTranslation();

  return (
    <div className="my-10 container mx-auto">
      <div className="flex items-center justify-center">
        <div className="border border-white rounded-lg p-2 flex flex-col lg:flex-row items-center">
          <Link
            className={`h-[52px] flex items-center justify-center px-3 rounded-lg w-[229px] text-2xl lg:text-base hover:scale-105 transition-[300] ${
              pathname === ROUTES.referFriend
                ? 'bg-primary text-white'
                : 'bg-transparent text-black dark:text-white'
            }`}
            to={ROUTES.referFriend}
          >
            <img
              src={
                pathname === ROUTES.referFriend
                  ? '/assets/icons/refer-a-friend.svg'
                  : theme === 'dark'
                  ? '/assets/icons/refer-a-friend.svg'
                  : '/assets/icons/refer-a-friend-black.svg'
              }
              alt="refer-a-friend"
              className="h-6 w-6 rtl:ml-2 mr-2"
            />
            {t('referAFriend')}
          </Link>
          <Link
            className={`h-[52px] flex items-center justify-center px-3 rounded-lg w-[229px] text-2xl lg:text-base hover:scale-105 transition-[300] ${
              pathname === ROUTES.ibProxy
                ? 'bg-primary text-white'
                : 'bg-transparent text-black dark:text-white'
            }`}
            to={ROUTES.ibProxy}
          >
            <img
              src={
                pathname === ROUTES.ibProxy
                  ? '/assets/icons/ip-proxy.svg'
                  : theme === 'dark'
                  ? '/assets/icons/ip-proxy.svg'
                  : '/assets/icons/ip-proxy-black.svg'
              }
              alt="refer-a-friend"
              className="h-6 w-6 rtl:ml-2 mr-2"
            />
            {t('IpProxy')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
